import React, { Component, Fragment} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

class StringAutoComplete extends Component {
    constructor(props) {
      super(props);
      this.state = {
        currentSuggestion: 0,
        filteredSuggestions: [],
        showSuggestions: false,
        inputValue: "",
        hasFocus: false
      };
    }

    focusOn = () => {
      this.setState({hasFocus: true});
    };

    focusOff = (event) => {
      this.setState({hasFocus: false}, () => {
        const currentInputText = document.getElementById(`${this.props.name}-autocomplete`).value;   
        this.setState({
          showSuggestions: false,
          inputValue: currentInputText
        }, () => {
          event.target.value = this.state.inputValue;
          this.props.onChange(event);
        });
      });
    };

    handleChange = (event) => {
        const { suggestions } = this.props;
        const inputValue = event.currentTarget.value;   
        const filteredSuggestions =
          suggestions.filter(
            suggestion =>
              suggestion.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
          );             
        this.setState({
          currentSuggestion: 0,
          filteredSuggestions,
          showSuggestions: true,
          inputValue: event.currentTarget.value
        });
      };

      handleClick = (event) => {
        this.setState({
          currentSuggestion: 0,
          filteredSuggestions: [],
          showSuggestions: false,
          inputValue: event.currentTarget.innerText
        }, () => {
          event.target = {
            name: this.props.name,
            value: this.state.inputValue,
            ...event.target
          };
          this.props.onChange(event);
        });
      };

      handleKeyDown = (event) => {
        const { currentSuggestion, filteredSuggestions } = this.state;
        if (event.keyCode === 13) { // Pressed the enter key
          if (!filteredSuggestions.length && this.state.inputValue) {
            const currentInputText = document.getElementById(`${this.props.name}-autocomplete`).value;   
            this.setState({
              showSuggestions: false,
              inputValue: currentInputText
            }, () => {
              event.target.value = this.state.inputValue;
              this.props.onChange(event);
            });        
          } else {
            this.setState({
              currentSuggestion: 0,
              showSuggestions: false,
              inputValue: this.props.lowercase? 
                            filteredSuggestions[currentSuggestion].toLowerCase():
                            filteredSuggestions[currentSuggestion]
            }, () => {
              event.target.value = this.state.inputValue;
              this.props.onChange(event);
            });
          }       
        } else if (event.keyCode === 38) { // Pressed the up arrow
          if (currentSuggestion === 0) {
            return;
          }
          this.setState({ currentSuggestion: currentSuggestion - 1 });
        }
        else if (event.keyCode === 40) { // Pressed the down arrow
          if (currentSuggestion - 1 === filteredSuggestions.length) {
            return;
          }
          this.setState({ currentSuggestion: currentSuggestion + 1 });
        }
      };

      render() {
        const darkMode = localStorage? (
          localStorage.getItem("theme") === "theme-dark"? true: false
        ): false;

        const {
          handleChange,
          handleClick,
          handleKeyDown,
          state: {
            currentSuggestion,
            filteredSuggestions,
            showSuggestions,
            inputValue
          }
        } = this;      
        let suggestionsListComponent;
        if (showSuggestions && inputValue) {
            if (filteredSuggestions.length) {
              suggestionsListComponent = (
                <ul>
                  {filteredSuggestions.map((suggestion, index) => {
                    let className;
                    if (index === currentSuggestion) {
                      className = "border-dark-md theme-dark-bg";
                    }
                    return (
                      <SuggestionCard
                        className={`${className} bor-0 p-2 ps-5 font-xssss text-grey-500 fw-500 pointer ${this.props.lowercase? "text-lowercase": ""} ${darkMode? "dark-mode-hover": ""}`}
                        key={index}
                        onMouseDown={handleClick} // We use mouseDown, because the click occurs after blur, causing the event not to trigger
                      >                     
                        {this.props.labelFromHit(suggestion)}
                      </SuggestionCard>
                    );
                  })}
                </ul>
              );
            } else {
              suggestionsListComponent = (
                <div>
                  <p className="bor-0 p-2 ps-5 font-xssss text-grey-500 fw-500">No suggestions available.</p>
                </div>
              );
            }
          }
          return (
            <Fragment>
              <input
                id={`${this.props.name}-autocomplete`}
                type="text"
                name={this.props.name}
                className="bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                value={inputValue}
                placeholder={this.props.placeholder}
                autoComplete="off"
                onFocus={this.focusOn}
                onBlur={this.focusOff}
              />
              {suggestionsListComponent}
            </Fragment>
          );
      }
}

const SuggestionCard = styled.li`
    img {
      max-height: 30px;
      object-fit: cover;
      margin: 10px;
    }
    &:hover {
        border: 2px #999 solid;
       &.dark-mode-hover {
          background-color: #1a2236
        }        
    }
`;

StringAutoComplete.propTypes = {
    suggestions: PropTypes.array.isRequired,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    lowercase: PropTypes.bool
};

export default StringAutoComplete;

