import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Redux
import { connect } from "react-redux";
import { editCompanyDetails } from "../../redux/actions/userActions";

export class EditProfileButton extends Component {
    state = {
        bio: "",
        website: "",
        location: "",
        showModal: false,
    };

    mapCompanyDetailsToState = (credentials) => {
        this.setState({
            bio: credentials.bio ? credentials.bio : "",
            website: credentials.website ? credentials.website : "",
            location: credentials.location ? credentials.location : "",
        });
    };

    handleOpen = () => {
        this.setState({ showModal: true });
        this.mapCompanyDetailsToState(this.props.credentials);
    };

    handleClose = () => {
        this.setState({ showModal: false });
    };

    handleChange = (event) => {
        event.preventDefault();
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleSubmit = () => {
        const companyDetails = {
            bio: this.state.bio,
            website: this.state.website,
            location: this.state.location,
        };
        this.props.editCompanyDetails(companyDetails);
        this.handleClose();
    };

    componentDidMount = () => {
        const { credentials } = this.props;
        this.mapCompanyDetailsToState(credentials);
    };

    render() {
        return (
            <Fragment>
                <div className="ms-auto pointer">
                    <a onClick={this.handleOpen} style={{ float: "right" }}>
                        <i className="btn-round-sm font-xs text-grey-900 feather-edit-2 me-2 bg-greylight" />
                    </a>
                </div>
                {this.state.showModal && (
                    <Container>
                        <Content className="card w-100 shadow-xss rounded-xxl">
                            <Header className="shadow-xss">
                                <h2 style={{ whiteSpace: "nowrap" }}>
                                    Edit Profile
                                </h2>

                                <CancelButtonArea className="card-body p-0">
                                    <a
                                        className="font-xssss fw-600 text-warning-500"
                                        onClick={this.handleClose}
                                    >
                                        <i
                                            className="btn-round-sm font-xs text-danger feather-x me-2 bg-danger-light"
                                            onClick={this.handleClose}
                                        />
                                        <br />
                                    </a>
                                </CancelButtonArea>
                            </Header>
                            <hr
                                style={{
                                    width: "100%",
                                    margin: 0,
                                    padding: 0,
                                    backgroundColor: "#adb5bd",
                                }}
                            />
                            <Editor>
                                <div className="card-body p-0 mt-3 position-relative">
                                    <label>
                                        <h5>Description</h5>
                                    </label>
                                    <textarea
                                        name="bio"
                                        className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg"
                                        cols="30"
                                        rows="10"
                                        placeholder="Tell us about your company"
                                        value={this.state.bio}
                                        onChange={this.handleChange}
                                        autoFocus={true}
                                    />
                                    <label>
                                        <h5>Website</h5>
                                    </label>
                                    <input
                                        type="text"
                                        name="website"
                                        className="bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg"
                                        placeholder="Add a link"
                                        value={this.state.website}
                                        onChange={this.handleChange}
                                        autoFocus={true}
                                    />
                                    <label>
                                        <h5>Location</h5>
                                    </label>
                                    <input
                                        type="text"
                                        name="location"
                                        className="bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg"
                                        placeholder="Where are you based?"
                                        value={this.state.location}
                                        onChange={this.handleChange}
                                        autoFocus={true}
                                    />
                                </div>
                            </Editor>
                            <BottomButtonArea>
                                <a
                                    className="p-2 lh-20 w100 bg-gold-gradient me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl"
                                    onClick={this.handleSubmit}
                                >
                                    <i className="font-xs text-white feather-save me-2 bg-transparent" />
                                    Save
                                </a>
                            </BottomButtonArea>
                        </Content>
                    </Container>
                )}
            </Fragment>
        );
    }
}

const Editor = styled.div`
    padding: 12px 24px;
    textarea {
        width: 100%;
        min-height: 100px;
        resize: none;
    }
    input {
        width: 100%;
        height: 35px;
        font-size: 16px;
        margin-bottom: 20px;
    }
`;

const BottomButtonArea = styled.div`
    padding: 12px 24px 12px 16px;
    a {
        float: right;
        cursor: pointer;
    }
`;

const CancelButtonArea = styled.div`
    a {
        color: red;
        float: right;
        font-weight: 500;
        cursor: pointer;
    }
`;

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    color: black;
    background-color: rgba(0, 0, 0, 0.8);
    animation: fadeIn 0.3s;
`;

const Header = styled.div`
    display: block;
    padding: 16px 20px;
    font-size: 14px;
    line-height: 1.5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
        font-weight: 700;
        color: black;
    }
    button {
        height: 40px;
        width: 40px;
        min-width: auto;
        color: rgba(0, 0, 0, 0.15);
        svg,
        img {
            pointer-events: none;
        }
    }
`;

const Content = styled.div`
    width: 100%;
    max-width: 552px;
    background-color: white;
    max-height: 90%;
    overflow: initial;
    border-radius: 12px;
    position: relative;
    display: flex;
    flex-direction: column;
    top: 32px;
    margin: 0 auto;
`;

EditProfileButton.propTypes = {
    editCompanyDetails: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    credentials: state.user.credentials,
});

export default connect(mapStateToProps, { editCompanyDetails })(EditProfileButton);
