import React, { Component } from "react";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import { abbreviate } from "../../util/NumberFormat";
import smileyFace from "../../images/smile.svg";
import frownyFace from "../../images/frown.svg";

// UI Components
import CommentModal from "./CommentModal";

// Redux
import { connect } from "react-redux";
import { likePost, unlikePost, dislikePost, undislikePost } from "../../redux/actions/dataActions";

export class PostReactionGroup extends Component {

    constructor() {
        super();
        this.state = {
            showModal: false,
        };
    }

    handleClick = (event) => {
       const authenticated = this.props.user.authenticated;
       if (authenticated) {
        event.preventDefault();
        this.setState({showModal: this.state.showModal? false: true});
       }
    };

    likedPost = () => {
        if (this.props.user.likes && this.props.user.likes.find(like => like.postId === this.props.postId)) {
            return true;
        } else {
            return false;
        }
    };

    dislikedPost = () => {
        if (this.props.user.dislikes && this.props.user.dislikes.find(dislike => dislike.postId === this.props.postId)) {
            return true;
        } else {
            return false;
        }
    };

    likePost = () => {
        const authenticated = this.props.user.authenticated;   
        if (authenticated) {
            if (!this.likedPost()) {
                if (this.dislikedPost()) {
                    this.props.undislikePost(this.props.postId);
                }
                this.props.likePost(this.props.postId);
            } else {
                this.props.unlikePost(this.props.postId);
            }            
        }      
    };

    dislikePost = () => {
        const authenticated = this.props.user.authenticated;   
        if (authenticated) {
            if (!this.dislikedPost()) {
                if (this.likedPost()) {
                    this.props.unlikePost(this.props.postId);
                }
                this.props.dislikePost(this.props.postId);
            } else {
                this.props.undislikePost(this.props.postId);
            }            
        }      
    };

    unlikePost = () => {
        const authenticated = this.props.user.authenticated;   
        if (authenticated) {
            if (this.likedPost()) {
                this.props.unlikePost(this.props.postId);
            } else {
                this.props.likePost(this.props.postId);
            }            
        }      
    };

    render() { 
        const authenticated = this.props.user.authenticated;      
        const { likeCount, dislikeCount, commentCount } = this.props;  
        const buttonMarkup = (
            <>
                <a onClick={this.likePost}>
                    <div style={{display: "inline-block", marginRight: "1.25em"}}>
                        <i className="feather-thumbs-up text-white bg-blue-gradient me-1 btn-round-xs font-xss"/>
                        {" "}
                        {likeCount? abbreviate(likeCount): 0}
                        <span className="d-none-xs">
                            {likeCount && likeCount === 1? " Like": " Likes"}
                        </span>
                    </div>
                </a>
                <a onClick={this.dislikePost}>
                    <div style={{display: "inline-block", marginRight: "1.25em"}}>
                        <i className="feather-thumbs-down text-white bg-red-gradient me-2 btn-round-xs font-xss"/>
                        {" "}
                        {dislikeCount? abbreviate(dislikeCount): 0}
                        <span className="d-none-xs">
                            {dislikeCount && dislikeCount === 1? " Dislike": " Dislikes"}
                        </span>
                    </div> 
                </a>
                <a onClick={this.handleClick}>
                    <i className="feather-message-circle text-dark text-grey-900 btn-round-sm font-lg"/>
                    {" "}
                    {commentCount? abbreviate(commentCount): 0}
                    <span className="d-none-xs">
                        {commentCount && commentCount === 1? " Comment": " Comments"}
                    </span>                
                </a> 
                <CommentModal
                    showModal={this.state.showModal}
                    handleClick={this.handleClick}
                    postId={this.props.postId}
                    userHandle={this.props.userHandle}
                />
            </>
        );
        const postReactionGroup = !authenticated? (
            <Link to="/login" style={{textDecoration: "inherit", color: "inherit"}}>
                {buttonMarkup}                      
            </Link>
        ) : (
            this.likedPost()? (
            <div style={{textAlign: "start"}}>
                {buttonMarkup}
                <hr style={{backgroundColor: "#adb5bd", cursor: "default"}}/>
                <span style={{color: "#0575e6", cursor: "default"}} className="font-xsss">
                    <span className="font-xsss">Liked </span>
                    <img src={smileyFace} alt="smile" height="18px" style={{marginBottom: "3px"}}/>
                </span>
            </div> 
            ) : (
                this.dislikedPost()? (
                    <div style={{textAlign: "start"}}>
                        {buttonMarkup}
                        <hr style={{backgroundColor: "#adb5bd", cursor: "default"}}/>
                        <span style={{color: "#e44d26", cursor: "default"}} className="font-xsss">
                            <span className="font-xsss">Disliked </span>
                            <img src={frownyFace} alt="frown" height="18px" style={{marginBottom: "3px"}}/>
                        </span>
                    </div> 
                ): (
                    buttonMarkup
                )                            
            )
        );
        return postReactionGroup;
    }
}

PostReactionGroup.propTypes = {
    user: PropTypes.object.isRequired,
    userHandle: PropTypes.string.isRequired,
    postId: PropTypes.string.isRequired,
    likePost: PropTypes.func.isRequired,
    dislikePost: PropTypes.func.isRequired,
    unlikePost: PropTypes.func.isRequired,
    undislikePost: PropTypes.func.isRequired,
    likeCount: PropTypes.number.isRequired,
    dislikeCount: PropTypes.number.isRequired,
    commentCount: PropTypes.number.isRequired
};

const mapStateToProps = (state) => ({
    user: state.user,
});

const mapActionsToProps = {
    likePost,
    unlikePost,
    dislikePost,
    undislikePost
};

export default connect(mapStateToProps, mapActionsToProps)(PostReactionGroup)
