import { Component, Fragment } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

// UI Components
import ReactPlayer from "react-player";
import ProgressDialog from "../../util/ProgressDialog";

// Redux
import { deleteEvent } from "../../redux/actions/dataActions";
import { getEventReservations } from "../../redux/actions/dataActions";

class EventDetailsButton extends Component {
    constructor() {
        super();
        this.state = {
            showModal: false,
        };
    }

    handleOpen = () => {
        this.props.getEventReservations(this.props.event.meetingNumber);
        this.setState({ showModal: true });
    };

    handleClose = () => {
        this.setState({ showModal: false });
    };

    handleDelete = () => {
        this.props.deleteEvent(this.props.event.meetingNumber);
        this.handleClose();
    };

    render() {
        dayjs.extend(relativeTime);

        return (
            <Fragment>
                {this.props.loading && <ProgressDialog />}
                <a onClick={this.handleOpen} className="float-right pointer">
                    <i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss" />
                </a>
                {this.state.showModal && (
                    <Container>
                        <Content className="card w-100 shadow-xss rounded-xxl">
                            <Header className="shadow-xss">
                                <h2 style={{ whiteSpace: "nowrap" }}>
                                    Event Details
                                </h2>
                                <CancelButtonArea className="card-body p-0">
                                    <a
                                        className="font-xssss fw-600 text-warning-500"
                                        onClick={this.handleClose}
                                    >
                                        <i
                                            className="btn-round-sm font-xs text-danger feather-x me-2 bg-danger-light"
                                            onClick={this.handleClose}
                                        />
                                        <br />
                                    </a>
                                </CancelButtonArea>
                            </Header>
                            <hr
                                style={{
                                    width: "100%",
                                    margin: 0,
                                    padding: 0,
                                    backgroundColor: "#adb5bd",
                                }}
                            />
                            <div style={{ overflowY: "scroll", overflowX: "hidden" }}>
                                <h2 className="fw-700 lh-3 font-xss ms-4 mt-4">
                                    {this.props.event.eventTitle}
                                </h2>
                                <p className="ms-4 font-xsss">
                                    {new Date(
                                        this.props.event.start._seconds * 1000
                                    ).toString()}
                                </p>
                                <p className="ms-4 font-xsss">
                                    {this.props.event.description
                                        ? this.props.event.description
                                        : "No description available"}
                                </p>
                                {this.props.event.mediaVideo && (
                                    <div className="card-body p-4 mb-3 rounded-3 overflow-hidden">
                                        <ReactPlayer
                                            width="100%"
                                            url={this.props.event.mediaVideo}
                                            controls={true}
                                        />
                                    </div>
                                )}
                                {this.props.user.credentials.userId ===
                                    this.props.event.company.userId &&
                                    this.props.reservations && (
                                        <>
                                            <h2 className="fw-700 lh-3 font-xss ms-4 mt-4 mb-2">
                                                Attendees
                                            </h2>
                                            {this.props.reservations.map(
                                                (r) => (
                                                    <div className="row mt-1 ms-2 mb-3">
                                                        <div className="col-7 pe-1 mt-0">
                                                            <span className="text-dark fw-500">{r.handle}</span>
                                                            <br className="p-0"/>
                                                            <div className="d-inline font-xsss text-left text-cyan">
                                                                {r.email}
                                                            </div>
                                                        </div>
                                                        <div className="col-5 ps-1 text-right">
                                                            <h4 className="font-xssss mt-1 fw-500 text-grey-500 pe-4">
                                                                {dayjs(
                                                                    new Date(
                                                                        r
                                                                            .createdAt
                                                                            ._seconds *
                                                                            1000
                                                                    ).toLocaleString()
                                                                ).fromNow()}
                                                            </h4>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </>
                                    )}
                            </div>
                            <BottomButtonArea>
                                {this.props.user.credentials.userId ===
                                    this.props.event.company.userId && (
                                    <a
                                        className="p-2 lh-20 w100 bg-red-gradient text-white me-2 text-center font-xssss fw-600 ls-1 rounded-xl"
                                        onClick={this.handleDelete}
                                    >
                                        <i className="font-xs text-white feather-trash-2 me-2 bg-transparent" />
                                        Delete
                                    </a>
                                )}
                            </BottomButtonArea>
                        </Content>
                    </Container>
                )}
            </Fragment>
        );
    }
}

const BottomButtonArea = styled.div`
    padding: 12px 24px 12px 16px;
    a {
        float: right;
        cursor: pointer;
    }
`;

const CancelButtonArea = styled.div`
    a {
        color: red;
        float: right;
        font-weight: 500;
        cursor: pointer;
    }
`;

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    color: black;
    background-color: rgba(0, 0, 0, 0.8);
    animation: fadeIn 0.3s;
`;

const Header = styled.div`
    display: block;
    padding: 16px 20px;
    font-size: 14px;
    line-height: 1.5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
        font-weight: 700;
        color: black;
    }
    button {
        height: 40px;
        width: 40px;
        min-width: auto;
        color: rgba(0, 0, 0, 0.15);
        svg,
        img {
            pointer-events: none;
        }
    }
`;

const Content = styled.div`
    width: 100%;
    max-width: 552px;
    background-color: white;
    max-height: 90%;
    overflow: initial;
    border-radius: 12px;
    position: relative;
    display: flex;
    flex-direction: column;
    top: 32px;
    margin: 0 auto;
`;

EventDetailsButton.propTypes = {
    user: PropTypes.object.isRequired,
    event: PropTypes.object.isRequired,
    deleteEvent: PropTypes.func.isRequired,
    getEventReservations: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    reservations: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
    loading: state.UI.loading,
    reservations: state.data.reservations,
});

const mapActionsToProps = {
    deleteEvent,
    getEventReservations,
};

export default connect(mapStateToProps, mapActionsToProps)(EventDetailsButton);
