import { Component, Fragment } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Redux
import { createRating } from "../../redux/actions/dataActions";

// Icons
import star from "../../images/star_gold_medium.png";
import thumbsUp from "../../images/thumbs_up_gold_medium.png";
import megaphone from "../../images/megaphone_gold_medium.png";

class  FeedbackForm extends Component {

    constructor() {
        super();
        this.state = {
            showModal: false,
            comments: "",
            rating: 0,
            index: (Math.random() * (3 - 0 + 0)) << 0
        };
    }

    handleOpen = () => {
        this.setState({ showModal: true });
    };

    handleClose = () => {
        this.setState({
            showModal: false,
            comments: "",
            rating: 0
        });
    };

    handleChange = (event) => {
        event.preventDefault();
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleSubmit = () => {
        if (this.state.rating !== 0) {
            const ratingDetails = {
                comments: this.state.comments,
                rating: this.state.rating
            };
            if (this.props.user.authenticated) {
                ratingDetails.email = this.props.user.credentials.email;
            }
            this.props.createRating(ratingDetails);           
            this.handleClose();
        }
    };

    handleClick = (buttonKey) => {
        this.setState({
            rating: buttonKey + 1
        });
    };

    render() {
        const titles = [
            "Tell us how we're doing?",
            "Love BLYNK? Not so much?",
            "We want to hear from you!",
        ];
        const icons = [star, thumbsUp, megaphone];
        const { index } = this.state;
    
        return (
            <Fragment>
                <FeedbackCard className="card w-100 shadow-xss rounded-xxl">
                    <div className="nav-caption fw-700 font-xssss text-grey-500">
                        <FeedbackTitle>{titles[index]}</FeedbackTitle>
                    </div>        
                    <div className="wrap" style={{ marginTop: "16px" }}>
                        <div className="card-body d-flex pt-0 ps-4 pe-4 pb-0 bor-0">
                            <figure>
                                <img
                                    src={icons[index]}
                                    alt=""
                                    className="w90 me-2 bg-white rounded-circle p-2"
                                />
                            </figure>
                            <p className="text-grey-500 font-xsss mt-3">
                                Please share your experience by clicking the 'Next' button below.                     
                            </p>
                        </div>
                        <div className="card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-4 float-right">
                            <a
                                onClick={this.handleOpen}
                                className="p-2 lh-20 w100 bg-gold-gradient me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl pointer"
                            >
                                Next{"   "}
                                <i className="feather-chevron-right"/>
                            </a>     
                        </div>
                    </div>
            </FeedbackCard>
                {this.state.showModal && (
                    <Container>
                        <Content className="card w-100 shadow-xss rounded-xxl">
                            <Header className="shadow-xss">
                                <h2 style={{ whiteSpace: "nowrap" }}>
                                    Feedback
                                </h2>
                                <CancelButtonArea className="card-body p-0">
                                    <a
                                        className="font-xssss fw-600 text-warning-500"
                                        onClick={this.handleClose}
                                    >
                                        <i
                                            className="btn-round-sm font-xs text-danger feather-x me-2 bg-danger-light"
                                            onClick={this.handleClose}
                                        />
                                        <br />
                                    </a>
                                </CancelButtonArea>
                            </Header>
                            <hr
                                style={{
                                    width: "100%",
                                    margin: 0,
                                    padding: 0,
                                    backgroundColor: "#adb5bd",
                                }}
                            />
                            <div style={{overflowY: "scroll"}}>                            
                                <RatingStarContainer>
                                    <label style={{padding: "12px 24px"}}>
                                        <h5>Overall Rating</h5>
                                    </label>
                                    <StarCounter>
                                        {this.state.rating}/5
                                    </StarCounter>                               
                                    <span>
                                        {
                                            [...Array(5).keys()].map(key => {
                                                const buttonClass = this.state.rating === 0? "text-grey-500 bg-greylight": (
                                                    key + 1  <= this.state.rating? "text-white bg-gold-gradient": "text-grey-500 bg-greylight"
                                                );
                                                return (
                                                    <a
                                                        key={key}
                                                        onClick={() => this.handleClick(key)}
                                                    >
                                                        <i className={`font-xs feather-star me-3 bg-greylight btn-round-sm pointer ${buttonClass}`}/>
                                                    </a>
                                                );
                                            })
                                        }                                                                
                                    </span>
                                </RatingStarContainer>
                                <Editor>
                                    <div className="card-body p-0 mt-3 position-relative">
                                        <label>
                                            <h5>Comments</h5>
                                        </label>
                                        <textarea
                                            name="comments"
                                            className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg"
                                            cols="30"
                                            rows="10"
                                            placeholder="Anything else you'd like us to know?"
                                            value={this.state.comments}
                                            onChange={this.handleChange}
                                            autoFocus={true}
                                        />                               
                                    </div>
                                </Editor>
                            </div>
                            <BottomButtonArea>
                                <a
                                    className={`p-2 lh-20 w100 ${this.state.rating === 0? "bg-greylight text-grey-500": "bg-gold-gradient text-white"} me-2 text-center font-xssss fw-600 ls-1 rounded-xl`}
                                    onClick={this.handleSubmit}
                                >
                                    <i className={`font-xs ${this.state.rating === 0? "text-grey-500": "text-white"} feather-send me-2 bg-transparent`}/>
                                    Send
                                </a>
                            </BottomButtonArea>
                        </Content>
                    </Container>
                )}
            </Fragment>
        );
    }

};

const FeedbackCard = styled.div`
    text-align: left;
    overflow: hidden;
    margin-bottom: 16px;
    background-color: #fff;
    border-radius: 12px;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    padding: 12px;
`;

const FeedbackTitle = styled.h4`
    font-size: 14px;
    font-weight: 700;
`;

const Editor = styled.div`
    padding: 12px 24px;
    textarea {
        width: 100%;
        min-height: 100px;
        resize: none;
    }
    input {
        width: 100%;
        height: 35px;
        font-size: 16px;
        margin-bottom: 20px;
    }
`;

const BottomButtonArea = styled.div`
    padding: 12px 24px 12px 16px;
    a {
        float: right;
        cursor: pointer;
    }
`;

const CancelButtonArea = styled.div`
    a {
        color: red;
        float: right;
        font-weight: 500;
        cursor: pointer;
    }
`;

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    color: black;
    background-color: rgba(0, 0, 0, 0.8);
    animation: fadeIn 0.3s;
`;

const Header = styled.div`
    display: block;
    padding: 16px 20px;
    font-size: 14px;
    line-height: 1.5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
        font-weight: 700;
        color: black;
    }
    button {
        height: 40px;
        width: 40px;
        min-width: auto;
        color: rgba(0, 0, 0, 0.15);
        svg,
        img {
            pointer-events: none;
        }
    }
`;

const Content = styled.div`
    width: 100%;
    max-width: 552px;
    background-color: white;
    max-height: 90%;
    overflow: initial;
    border-radius: 12px;
    position: relative;
    display: flex;
    flex-direction: column;
    top: 32px;
    margin: 0 auto;
`;

const RatingStarContainer = styled.div`
    text-align: center;   
    padding: 12px 24px;
`;

const StarCounter = styled.h1`
    font-size: 56px;
`;

FeedbackForm.propTypes = {
    user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, { createRating })(FeedbackForm);
