import { connect } from "react-redux";
import styled from "styled-components";
import { Component, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import progressSpinner from "../images/spin-loader.svg";
import {
    loginUserWithGoogle,
    loginUserWithFacebook,
    loginUserWithEmailAndPassword,
} from "../redux/actions/userActions";

class Login extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            errors: [], // TODO: handle validation errors
        };
    }

    // TODO: find a replacement for this deprecated lifecycle method
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.UI.errors) {
            this.setState({ errors: nextProps.UI.errors });
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const userData = {
            email: this.state.email,
            password: this.state.password,
        };
        this.props.loginUserWithEmailAndPassword(userData, this.props.navigate);
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    googleSignIn = () => {
        this.props.loginUserWithGoogle(this.props.navigate);
    };

    facebookSignIn = () => {
        this.props.loginUserWithFacebook(this.props.navigate);
    };

    render() {
        const body = document.body;
        const {
            UI: { loading },
        } = this.props;
        body.classList.replace("theme-dark", "theme-light");

        return (
            <Container>
                <Fragment>
                    <div className="main-wrap">
                        <div className="row">
                            <div
                                className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
                                style={{
                                    backgroundImage: `url("https://images.unsplash.com/photo-1641574003327-aebaf68f4b9a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80")`,
                                }}
                            >
                                <Link to="/">
                                    <img
                                        src="images/blynk_gold.png"
                                        width="370"
                                        alt="logo"
                                        style={{
                                            marginTop: "8px",
                                            marginLeft: "32px",
                                        }}
                                    />
                                </Link>
                            </div>
                            <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                                <div className="card shadow-none border-0 ms-auto me-auto login-card">
                                    <div className="card-body rounded-0 text-left">
                                        <h2 className="fw-700 display1-size display2-md-size mb-3">
                                            Welcome to BLYNK Enterprise.
                                            <br />
                                            Login to continue.
                                        </h2>
                                        <form onSubmit={this.handleSubmit}>
                                            <LoginInput
                                                name="email"
                                                type="email"
                                                value={this.state.email}
                                                onChange={this.handleChange}
                                                className="form-control w-100 rounded-xxl p-2 ps-5 font-xss fw-500"
                                                placeholder="Email address"
                                            />
                                            <LoginInput
                                                name="password"
                                                type="password"
                                                value={this.state.password}
                                                onChange={this.handleChange}
                                                className="form-control w-100 rounded-xxl p-2 ps-5 font-xss fw-500"
                                                placeholder="Password"
                                            />
                                            <SubmitButton
                                                disabled={loading}
                                                type="submit"
                                            >
                                                {loading ? (
                                                    <img
                                                        src={progressSpinner}
                                                        alt="loading"
                                                    />
                                                ) : (
                                                    "Login"
                                                )}
                                            </SubmitButton>
                                        </form>
                                        <h6 className="mb-0 text-center bg-white fw-500 font-xsss text-grey-500 mb-3">
                                            Don't have an account?{" "}
                                            <Link to="/sign-up">Sign Up</Link>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            </Container>
        );
    }
}

const Container = styled.div`
    padding: 0px;
`;

const LoginInput = styled.input`
    width: 100%;
    height: 35px;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.6);
`;

const SubmitButton = styled.button`
    display: flex;
    justify-content: center;
    color: #ffffff;
    background-color: #000000;
    align-items: center;
    height: 56px;
    width: 100%;
    border-radius: 28px;
    margin-bottom: 16px;
    box-shadow: inset 0 0 0 1px rgb(0 0 0 / 60%),
        inset 0 0 0 2px rgb(0 0 0 / 0%) inset 0 0 0 1px rgb(0 0 0 / 0);
    vertical-align: middle;
    z-index: 0;
    transition-duration: 167ms;
    font-size: 20px;
    img {
        width: 36px;
        height: 36px;
    }
    &:hover {
        background-color: rgba(207, 207, 207, 0.25);
        color: rgba(0, 0, 0, 0.75);
    }
`;

Login.propTypes = {
    loginUserWithEmailAndPassword: PropTypes.func.isRequired,
    loginUserWithGoogle: PropTypes.func.isRequired,
    loginUserWithFacebook: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired,
};

const WithNavigate = (props) => {
    const navigate = useNavigate();
    return <Login {...props} navigate={navigate} />;
};

const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI,
});

const mapActionsToProps = {
    loginUserWithGoogle,
    loginUserWithFacebook,
    loginUserWithEmailAndPassword,
};

export default connect(mapStateToProps, mapActionsToProps)(WithNavigate);
