// User Reducer Types
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_USER = "SET_USER";
export const LOADING_USER = "LOADING_USER";
export const MARK_NOTIFICATIONS_READ = "MARK_NOTIFICATIONS_READ";

//UI Reducer Types
export const SET_ERRORS = "SET_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const STOP_LOADING_UI = "STOP_LOADING_UI";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

// Data Reducer Types
export const LOADING_DATA = "LOADING_DATA";
export const SET_POSTS = "SET_POSTS";
export const SET_POST = "SET_POST";
export const LIKE_POST = "LIKE_POST";
export const DISLIKE_POST = "DISLIKE_POST";
export const UNLIKE_POST = "UNLIKE_POST";
export const UNDISLIKE_POST = "UNDISLIKE_POST";
export const DELETE_POST = "DELETE_POST";
export const CREATE_POST = "CREATE_POST";
export const SUBMIT_COMMENT = "SUBMIT_COMMENT";
export const SET_JOBS = "SET_JOBS";
export const SET_PROFILE = "SET_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const SET_EVENTS = "SET_EVENTS";
export const CREATE_EVENT = "CREATE_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";
export const SET_RESERVATIONS = "SET_RESERVATIONS";
export const STOP_LOADING_DATA = "STOP_LOADING_DATA";





