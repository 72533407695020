import React,{Component} from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import PostReactionGroup from './PostReactionGroup';
import CommentModal from './CommentModal';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { toAddress } from '../../util/companyAddress';
import { renderWithLinks } from "../../util/TextFormat";

class PostView extends Component {
    constructor() {
        super();
        this.state = {
            showModal: false,
        };
    }
    
    handleClick = (event) => {
        event.preventDefault();
        this.setState({showModal: this.state.showModal? false: true});
    };
    
    render() {        
        const {
            userHandle,
            userId,
            createdAt ,
            body,
            userImage ,
            mediaImage ,
            mediaVideo,
            postId,
            commentCount,
            likeCount,
            dislikeCount
        } = this.props;

        const profileUrl = `/users/${toAddress(userHandle, userId)}`;
        
        return (
            <div className="card w-100 shadow-xss rounded-xxl p-4 mb-3">
                <div className="card-body p-0 d-flex">
                    <Link className="pointer" to={profileUrl}>
                        <figure className="avatar me-3">
                            <img 
                                src={userImage}
                                alt=""
                                style={{maxHeight: "45px", objectFit: "cover"}}
                                className="shadow-sm rounded-circle w45"
                            />
                        </figure>
                    </Link>
                    <PostHeaderText
                        className="fw-700 text-dark font-xssss mt-1"
                        to={profileUrl}
                    > {userHandle} <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"> {createdAt}</span></PostHeaderText>
                    <div className="ms-auto pointer">
                        <a onClick={this.handleClick}>
                            <i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"/>
                        </a>
                    </div>                    
                </div>
                {mediaVideo?  
                <div className="card-body p-0 mb-3 rounded-3 overflow-hidden">
                    <ReactPlayer width="100%" url={mediaVideo}/>
                </div>
                : ''}
                <div className="card-body p-0 me-lg-5">
                    <PostText className="fw-500 text-black-500 lh-26 font-xssss w-100 mb-2">{renderWithLinks(body)}</PostText>
                </div>
                {mediaImage?
                <div className="card-body d-block p-0 mb-3">
                    <div className="row ps-2 pe-2">
                        <div className="col-sm-12 p-1"><img src={mediaImage} className="rounded-3 w-100" alt="post" /></div>                                        
                    </div>
                </div>
                : ''}
                <div className="card-body d-flex p-0">
                    <div
                        className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2"
                        onClick={this.toggleActive}
                    >
                           <PostReactionGroup
                                likeCount={likeCount}
                                dislikeCount={dislikeCount}
                                postId={postId}
                                commentCount={commentCount}
                                userHandle={this.props.userHandle}
                            />                           
                    </div>         
                </div>
                <CommentModal
                    showModal={this.state.showModal}
                    handleClick={this.handleClick}
                    postId={postId}
                    userHandle={this.props.userHandle}
                />
            </div>                            
        );
    }
}

const PostText = styled.p`
    text-align: left;
    white-space: pre-wrap;
`;

const PostHeaderText = styled(Link)`
    text-align: left;
`;

PostView.propTypes = {
    userHandle: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    userImage: PropTypes.string.isRequired,
    mediaImage: PropTypes.string.isRequired,
    mediaVideo: PropTypes.string.isRequired,
    postId: PropTypes.string.isRequired,
    commentCount: PropTypes.number.isRequired,
    likeCount: PropTypes.number.isRequired,
    dislikeCount: PropTypes.number.isRequired,
};

export default PostView;