import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Redux
import { connect } from "react-redux";
import { deletePost } from "../../redux/actions/dataActions";

class DeletePostButton extends Component {
    constructor() {
        super();
        this.state = {
            showModal: false,
        };
    }

    handleOpen = () => {
        this.setState({showModal: true});
    };

    handleClose = () => {
        this.setState({showModal: false});
    };

    deletePost = () => {
        this.props.deletePost(this.props.postId);
        this.setState({showModal: false});
    };

    render() {
        return (
            <Fragment>
                <div className="ms-auto pointer">
                    <a onClick={this.handleOpen}>
                        <i className="btn-round-sm font-xs text-grey-900 feather-trash-2 me-2 bg-greylight"/>
                    </a>
                </div>
                { this.state.showModal && 
                    <Container>
                        <ModalCard className="card w-100 shadow-xss rounded-xxl">
                            <div className="nav-caption fw-700 font-xssss text-grey-500">
                                <ModalTitle>
                                    Delete this post?
                                </ModalTitle>
                            </div>
                            <div style={{marginTop: "16px"}}>                        
                                <div className="card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-4">
                                    <a onClick={this.deletePost} className="p-2 lh-20 w100 bg-red-gradient me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl pointer">
                                        <i className="font-xs text-white feather-trash-2 me-2 bg-transparent"/>
                                        Delete
                                    </a>
                                    <a onClick={this.handleClose} className="p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl pointer">Cancel</a>
                                </div>
                            </div>
                        </ModalCard>
                    </Container>
                }         
            </Fragment>
        );
    }
}

const Container = styled.div`
     position: fixed;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     z-index: 9999;
     color: black;
     background-color: rgba(0, 0, 0, 0.8);
     animation: fadeIn 0.3s;
`;

const ModalTitle = styled.h3`
    left: 45px;
    font-weight: 700;
`;

const ModalCard = styled.div`
  width: 100%;
  max-width: 400px;  
  text-align: left;
  overflow: hidden;
  margin: 90px auto;
  background-color: #fff;
  border-radius: 12px;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 12px;
`;

DeletePostButton.propTypes = {
    deletePost: PropTypes.func.isRequired,
    postId: PropTypes.string.isRequired
};


export default connect(null, { deletePost })((DeletePostButton));