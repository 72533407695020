import {
    PaymentElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import PropTypes from "prop-types";
import { useState } from "react";
import { connect } from "react-redux";
import loadingSpinner from "../..//images/spin-loader.svg";
import styled from "styled-components";
import ErrorDialog from "../../util/ErrorDialog";
import ProgressDialog from "../../util/ProgressDialog";

const PaymentForm = (props) => {
    const {
        UI: { loading },
    } = props;

    const [loadingStripe, setLoading] = useState(true);
    const [error, setError] = useState("No error found :)");
    const stripe = useStripe();
    const elements = useElements();

    const stopLoadingStripe = () => {
        setLoading(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Exit if the Stripe Promise is has not yet resolved
        if (!stripe || !elements) {
            return;
        }

        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: "https://business.joinblynk.com/search", // TODO: consider adding confirmation message or route
            },
        });

        if (result.error) {
            setError(result.error.message);
            console.log(result.error.message);
        }
    };
    return (
        <>
            {props.UI.loading && <ProgressDialog />}
            {error ? true : false && <ErrorDialog message={error} />}
            {props.user.authenticated ? (
                props.user.credentials.client_secret ? (
                    <>
                        {loadingStripe && (
                            <LoadingSpinnerWrapper>
                                <LoadingSpinner
                                    src={loadingSpinner}
                                    alt="loading"
                                />
                            </LoadingSpinnerWrapper>
                        )}
                        <form onSubmit={handleSubmit}>
                            <PaymentElement onReady={stopLoadingStripe} />
                            <SubmitButton
                                disabled={loading || !stripe}
                                type="submit"
                                className={`mt-3 ${loadingStripe && "d-none"}`}
                            >
                                {loading ? (
                                    <img src={loadingSpinner} alt="loading" />
                                ) : (
                                    "Submit"
                                )}
                            </SubmitButton>
                        </form>
                    </>
                ) : (
                    <LoadingSpinnerWrapper>
                        <LoadingSpinner src={loadingSpinner} alt="loading" />
                    </LoadingSpinnerWrapper>
                )
            ) : (
                <div>Please login to purchase an Enterprise Plan.</div>
            )}
        </>
    );
};

const LoadingSpinner = styled.img`
    width: 180px;
    height: 180px;
    align: center;
`;

const LoadingSpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 380px;
`;

const SubmitButton = styled.button`
    display: flex;
    justify-content: center;
    color: #ffffff;
    background-color: #000000;
    align-items: center;
    height: 56px;
    width: 100%;
    border-radius: 28px;
    margin-bottom: 16px;
    box-shadow: inset 0 0 0 1px rgb(0 0 0 / 60%),
        inset 0 0 0 2px rgb(0 0 0 / 0%) inset 0 0 0 1px rgb(0 0 0 / 0);
    vertical-align: middle;
    z-index: 0;
    transition-duration: 167ms;
    font-size: 20px;
    img {
        width: 36px;
        height: 36px;
    }
    &:hover {
        background-color: rgba(207, 207, 207, 0.25);
        color: rgba(0, 0, 0, 0.75);
    }
`;

PaymentForm.propTypes = {
    user: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI,
});

export default connect(mapStateToProps, null)(PaymentForm);
