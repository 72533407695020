import { Component, Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import progressSpinner from "../images/spin-loader.svg";

// Redux
import { connect } from "react-redux";
import {
    onboardCompany,
} from "../redux/actions/userActions";

class SignUp extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            confirmPassword: "",
            companyName: "",
            errors: [],
        };
    }

    // TODO: find a replacement for this deprecated lifecycle method
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.UI.errors) {
            this.setState({ errors: nextProps.UI.errors });
        }
    }

    formatName = (name) => {
        return name
            .toLowerCase()
            .replace(/\b\w/g, (l) => l.toUpperCase())
            .trim();
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const newCompanyData = {
            email: this.state.email,
            password: this.state.password,
            confirmPassword: this.state.confirmPassword,
            handle: this.formatName(this.state.companyName),
        };
        this.props.onboardCompany(newCompanyData, this.props.navigate);
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    render() {
        const body = document.body;
        const {
            UI: { loading },
        } = this.props;
        const { errors } = this.state;
        body.classList.replace("theme-dark", "theme-light");

        return (
            <Container>
                <Fragment>
                    <div className="main-wrap">
                        <div className="row">
                            <div
                                className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
                                style={{
                                    backgroundImage: `url("https://images.unsplash.com/photo-1641574003327-aebaf68f4b9a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80")`,
                                }}
                            >
                                <Link to="/">
                                    <img
                                        src="images/blynk_gold.png"
                                        width="370"
                                        alt="logo"
                                        style={{
                                            marginTop: "8px",
                                            marginLeft: "32px",
                                        }}
                                    />
                                </Link>
                            </div>
                            <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                                <div className="card shadow-none border-0 ms-auto me-auto login-card">
                                    <div className="card-body rounded-0 text-left">
                                        <h2 className="fw-700 display1-size display2-md-size mb-3">
                                            Sign up now.
                                            <br />
                                            Excellence awaits.
                                        </h2>
                                        <form onSubmit={this.handleSubmit}>
                                            <SignUpInput
                                                name="companyName"
                                                type="text"
                                                value={this.state.companyName}
                                                onChange={this.handleChange}
                                                className="form-control w-100 rounded-xxl p-2 ps-5 font-xss fw-500"
                                                placeholder="Company name"
                                            />
                                            <SignUpInput
                                                name="email"
                                                type="email"
                                                value={this.state.email}
                                                onChange={this.handleChange}
                                                className="form-control w-100 rounded-xxl p-2 ps-5 font-xss fw-500"
                                                placeholder="Email address"
                                            />
                                            <SignUpInput
                                                name="password"
                                                type="password"
                                                value={this.state.password}
                                                onChange={this.handleChange}
                                                className="form-control w-100 rounded-xxl p-2 ps-5 font-xss fw-500"
                                                placeholder="Password"
                                            />
                                            <SignUpInput
                                                name="confirmPassword"
                                                type="password"
                                                value={
                                                    this.state.confirmPassword
                                                }
                                                onChange={this.handleChange}
                                                className="form-control w-100 rounded-xxl p-2 ps-5 font-xss fw-500"
                                                placeholder="Confirm password"
                                            />
                                            <SubmitButton
                                                disabled={loading}
                                                type="submit"
                                            >
                                                {loading ? (
                                                    <img
                                                        src={progressSpinner}
                                                        alt="loading"
                                                    />
                                                ) : (
                                                    "Sign Up"
                                                )}
                                            </SubmitButton>
                                        </form>
                                        <h6 className="mb-0 text-center bg-white fw-500 font-xsss text-grey-500 mb-3">
                                            Already have an account?{" "}
                                            <Link to="/login">Login</Link>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            </Container>
        );
    }
}

const Container = styled.div`
    padding: 0px;
`;

const SignUpInput = styled.input`
    width: 100%;
    height: 35px;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.6);
`;

const SignUpButton = styled.button`
    justify-content: center;
    background-color: #fff;
    align-items: center;
    height: 56px;
    width: 168px;
    border-radius: 28px;
    margin-bottom: 16px;
    box-shadow: inset 0 0 0 1px rgb(0 0 0 / 60%),
        inset 0 0 0 2px rgb(0 0 0 / 0%) inset 0 0 0 1px rgb(0 0 0 / 0);
    vertical-align: middle;
    z-index: 0;
    transition-duration: 167ms;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.6);
    img {
        width: 36px;
        height: 36px;
    }
    &:hover {
        background-color: rgba(207, 207, 207, 0.25);
        color: rgba(0, 0, 0, 0.75);
    }
`;

const SubmitButton = styled.button`
    display: flex;
    justify-content: center;
    color: #ffffff;
    background-color: #000000;
    align-items: center;
    height: 56px;
    width: 100%;
    border-radius: 28px;
    margin-bottom: 16px;
    box-shadow: inset 0 0 0 1px rgb(0 0 0 / 60%),
        inset 0 0 0 2px rgb(0 0 0 / 0%) inset 0 0 0 1px rgb(0 0 0 / 0);
    vertical-align: middle;
    z-index: 0;
    transition-duration: 167ms;
    font-size: 20px;
    img {
        width: 36px;
        height: 36px;
    }
    &:hover {
        background-color: rgba(207, 207, 207, 0.25);
        color: rgba(0, 0, 0, 0.75);
    }
`;

const LabeledSeparator = styled.h5`
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #747474;
    line-height: 0.1em;
    margin: 10px 0 20px;

    span {
        background: #fff;
        padding: 0 10px;
        color: #747474;
    }
`;

SignUp.propTypes = {
    loginUserWithGoogle: PropTypes.func.isRequired,
    loginUserWithFacebook: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired,
    onboardCompany: PropTypes.func.isRequired,
};

const WithNavigate = (props) => {
    const navigate = useNavigate();
    return <SignUp {...props} navigate={navigate} />;
};

const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI,
});

const mapActionsToProps = {
    onboardCompany
};

export default connect(mapStateToProps, mapActionsToProps)(WithNavigate);
