import styled from "styled-components";
import Rightside from "../components/layout/Rightside";
import JobSearch from "../components/jobs/JobSearch";
import Navbar from "../components/layout/Navbar";

const Jobs = () => {
    return (
        <Container className="main-content right-chat-active">
            <Navbar />
            <Layout>
                <JobSearch />
                <Rightside />
            </Layout>
        </Container>
    );
};

const Container = styled.div`
    position: sticky;
    min-height: 100vh;
    padding-top: 72px;
    max-width: 100%;
    padding-left: 16px;
    padding-bottom: 24px;
    padding-right: 16px;
`;

const Layout = styled.div`
    display: grid;
    grid-template-areas: "main rightside";
    grid-template-columns: minmax(0, 12fr) minmax(300px, 7fr);
    column-gap: 25px;
    row-gap: 25px;
    margin: 25px 0;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        padding: 0 5px;
    }
`;

export default Jobs;
