import { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const Event = () => {
    const queryString = window.location.search;
    useEffect(() => {
        window.location.replace(`https://events.joinblynk.com${queryString}`);
    }, []);
    return null;
};

Event.propTypes = {
    user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, null)(Event);
