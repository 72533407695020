import { React, Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { toAddress } from "../../util/companyAddress";
import { renderWithLinks, prefixUrl } from "../../util/TextFormat";

// UI Components
import LoadingBar from "../../util/LoadingBar";
import CommentModal from "../post/CommentModal";
import PostView from "../post/PostView";
import EditProfileButton from "../profile/EditProfileButton";
import EditProfilePicButton from "../profile/EditProfilePicButton";

// Redux
import { connect } from "react-redux";

const CompanyContent = (props) => {
    let {
        data: {
            loading,
            posts,
            profile: { handle, imageUrl, email, userId, website },
        },
        openPost,
    } = props;
    dayjs.extend(relativeTime);

    const [bio, setBio] = useState(props.data.profile.bio);
    const [location, setLocation] = useState(props.data.profile.location);

    useEffect(() => {
        setBio(props.data.profile.bio);
    }, [props.data.profile.bio]);

    useEffect(() => {
        setLocation(props.data.profile.location);
    }, [props.data.profile.location]);

    const address = toAddress(handle, userId);

    const [activeTab, setActiveTab] = useState("profile");

    const profileMarkup = (
        <>
            <SectionCard className="card w-100 shadow-xss rounded-xxl">
                <div className="nav-caption fw-700 font-xssss text-grey-500">
                    {props.user.credentials.email === email && (
                        <EditProfileButton />
                    )}
                    <SectionTitle>About us</SectionTitle>
                </div>

                <div className="wrap">
                    <p
                        style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            whiteSpace: "pre-wrap",
                        }}
                    >
                        {bio
                            ? renderWithLinks(bio)
                            : "This company does not have a description."}
                    </p>
                </div>
            </SectionCard>
        </>
    );

    const postsMarkup = loading ? (
        <LoadingBar />
    ) : posts === null ? (
        <p>This user doesn't have any posts yet 😑</p>
    ) : !openPost ? (
        posts.map((post, index) => {
            return (
                <PostView
                    key={index}
                    postId={post.postId}
                    mediaVideo={post.mediaVideo ?? ""}
                    mediaImage={post.mediaImage ?? ""}
                    userImage={post.userImage ?? "images/user.svg"}
                    userHandle={post.userHandle}
                    userId={post.userId}
                    createdAt={dayjs(
                        new Date(
                            post.createdAt._seconds * 1000
                        ).toLocaleString()
                    ).fromNow()}
                    body={post.body}
                    commentCount={post.commentCount}
                    likeCount={post.likeCount}
                    dislikeCount={post.dislikeCount}
                />
            );
        })
    ) : (
        posts.map((post, index) => {
            if (post.postId !== openPost) {
                <PostView
                    key={index}
                    postId={post.postId}
                    mediaVideo={post.mediaVideo ?? ""}
                    mediaImage={post.mediaImage ?? ""}
                    userImage={post.userImage ?? "images/user.svg"}
                    userHandle={post.userHandle}
                    userId={post.userId}
                    createdAt={dayjs(
                        new Date(
                            post.createdAt._seconds * 1000
                        ).toLocaleString()
                    ).fromNow()}
                    body={post.body}
                    commentCount={post.commentCount}
                    likeCount={post.likeCount}
                    dislikeCount={post.dislikeCount}
                />;
            } else {
                <>
                    <CommentModal />
                    <PostView
                        key={index}
                        postId={post.postId}
                        mediaVideo={post.mediaVideo ?? ""}
                        mediaImage={post.mediaImage ?? ""}
                        userImage={post.userImage ?? "images/user.svg"}
                        userHandle={post.userHandle}
                        userId={post.userId}
                        createdAt={dayjs(
                            new Date(
                                post.createdAt._seconds * 1000
                            ).toLocaleString()
                        ).fromNow()}
                        body={post.body}
                        commentCount={post.commentCount}
                        likeCount={post.likeCount}
                        dislikeCount={post.dislikeCount}
                    />
                </>;
            }
        })
    );

    const renderTabContent = () => {
        switch (activeTab) {
            case "profile":
                return profileMarkup;
            case "activity":
                return postsMarkup;
            default:
                return (
                    <p
                        style={{ textAlign: "center", padding: "32px" }}
                        className="text-dark"
                    >
                        This user has not uploaded any content
                    </p>
                );
        }
    };

    return (
        <>
            <ProfileContainer
                className="card w-100 p-0 bg-white shadow-xss rounded-xxl"
                style={{ marginBottom: "16px" }}
            >
                <div
                    className="card-body p-0 rounded-xxl overflow-hidden m-3"
                    style={{ maxHeight: "150px" }}
                >
                    <img
                        src={
                            "https://i.pinimg.com/originals/49/61/5f/49615f86f34e3b5461a82e7a7d83121b.jpg"
                        }
                        alt="banner"
                        style={{ maxHeight: "2000px", width: "100%" }}
                    />
                </div>
                <div className="card-body p-0 position-relative">
                    <figure className="avatar mt--6 position-relative w75 z-index-1 w100 z-index-1 ms-auto me-auto">
                        <img
                            src={imageUrl}
                            className="p-1 bg-white rounded-xl w-100"
                        />
                    </figure>
                    {props.user.credentials.email === email && (
                        <ProfilePicButtonContainer>
                            <EditProfilePicButton />
                        </ProfilePicButtonContainer>
                    )}

                    <h4 className="font-xs ls-1 fw-700 text-grey-900 text-center">
                        {handle}{" "}
                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500 pb-2">
                            {address}
                        </span>
                    </h4>
                    <div className="d-flex align-items-center pt-0 position-absolute left-15 top-10 mt-4 ms-2">
                        {location && (
                            <div className="card-body d-flex d-none-xs pt-0">
                                <i className="feather-map-pin text-grey-500 me-3 font-lg" />
                                <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                                    {location}
                                </h4>
                            </div>
                        )}
                    </div>
                    <div className="d-flex align-items-center justify-content-center position-absolute right-15 top-10 mt-2 me-2">
                        <a
                            href={website ? prefixUrl(website) : "#"}
                            target="_blank"
                            rel="noreferer noopener"
                            className="d-none d-lg-block bg-black p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3"
                        >
                            Learn More
                        </a>
                    </div>
                </div>
                <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
                    <ul
                        className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4"
                        id="pills-tab"
                        role="tablist"
                        style={{ cursor: "pointer" }}
                    >
                        <li className="active list-inline-item me-5">
                            <a
                                className={`fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block ${
                                    activeTab === "profile" && "active"
                                }`}
                                onClick={() => setActiveTab("profile")}
                                data-toggle="tab"
                            >
                                Company
                            </a>
                        </li>
                        <li className="list-inline-item me-5">
                            <a
                                className={`fw-700 me-sm-5 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block ${
                                    activeTab === "media" && "active"
                                }`}
                                onClick={() => setActiveTab("media")}
                                data-toggle="tab"
                            >
                                Media
                            </a>
                        </li>
                    </ul>
                </div>
            </ProfileContainer>
            {renderTabContent()}
        </>
    );
};

const ProfileContainer = styled.div`
    margin-bottom: 16px;
`;

const SectionCard = styled.div`
    text-align: left;
    overflow: hidden;
    margin-bottom: 16px;
    background-color: #fff;
    border-radius: 12px;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    padding: 12px 12px 12px 45px;
`;

const SectionTitle = styled.h4`
    font-weight: 700;
`;

const ProfilePicButtonContainer = styled.span`
    position: absolute;
    top: 10%;
    left: calc(50% + 30px);
    z-index: 64;
`;

CompanyContent.propTypes = {
    data: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    openPost: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    data: state.data,
    user: state.user,
});

export default connect(mapStateToProps)(CompanyContent);
