export const prefixUrl = (url) => {
    const INCLUDES_PROTOCOL_REGEX = new RegExp("^(http|https)://", "i");
    return `${INCLUDES_PROTOCOL_REGEX.test ? "http://" : ""}${url}`;
};

const followLink = (url) => {
    window.open(prefixUrl(url), "_blank", "noreferrer,noopener");
};

export const renderWithLinks = (text) => {
    const URL_REGEX =
        /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const EXCLUDES_EMAIL_REGEX = /.*@.*/;

    return text.split(" ").map((token) => {
        return URL_REGEX.test(token)
            ? EXCLUDES_EMAIL_REGEX.test(token)
                ? token.split("\n").map((emailFragment) => {
                      return URL_REGEX.test(emailFragment) ? (
                          `\n ${emailFragment} `
                      ) : (
                          <a href={`mailto:${emailFragment}`}>
                              {emailFragment}{" "}
                          </a>
                      );
                  })
                : token.split("\n").map((urlFragment) => {
                      return !urlFragment.includes(".") ? (
                          `\n ${urlFragment} `
                      ) : (
                          <a
                              href="#"
                              onClick={(e) => {
                                  e.preventDefault();
                                  followLink(urlFragment);
                              }}
                          >
                              {urlFragment}{" "}
                          </a>
                      );
                  })
            : token + " ";
    });
};
