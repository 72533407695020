import { React, useState } from "react";
import searchClient from "../../util/algoliaConfig";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import mixpanel from "../../util/mixpanel";

// Images
import defaultProfilePic from "../../images/user.svg";
import coverGraphic from "../../images/town.svg";

const SearchList = (props) => {
    const index = searchClient.initIndex("blynk_users");
    const authenticated = props.user.authenticated;

    const [state, setState] = useState({
        people: [],
        query: "",
    });

    const handleChange = (event) => {
        event.preventDefault();
        setState({
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        index
            .search(state.query, {
                hitsPerPage: 12,
            })
            .then(({ hits }) => {
                setState({
                    people: hits,
                    ...state,
                });
            });
        mixpanel.track("Member Search", {
            query: state.query,
            authenticated: authenticated,
        });
    };

    const followLink = (url) => {
        window.open(url, "_blank", "noreferrer,noopener");
    };

    const abbreviateName = (fullName) => {
        const names = fullName.trim().split(" ");
        if (names[0] === 1) {
            return names[0];
        } else {
            const surnames = names[names.length - 1].split("-");
            return `${names[0]} ${surnames[surnames.length - 1][0]}.`;
        }
    };

    return (
        <>
            <div className="middle-sidebar-left pe-0 ps-4">
                <div className="row">
                    <div className="card shadow-xss w-100 h75 d-block p-3 mb-3 rounded-xxl float-left">
                        <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center">
                            <TitleIcon className="feather-users" />
                            <Title>{"Members "}</Title>
                            <form
                                onSubmit={handleSubmit}
                                className="pt-0 pb-0 ms-auto"
                            >
                                <div className="search-form-2 ms-2">
                                    <a onClick={handleSubmit}>
                                        <i className="ti-search font-xss pointer" />
                                    </a>
                                    <input
                                        type="text"
                                        name="query"
                                        onChange={handleChange}
                                        className="mb-0 bg-greylight theme-dark-bg border-0 text-dark font-xs"
                                        placeholder="Search"
                                    />
                                </div>
                            </form>
                        </h2>
                    </div>
                    <div className="col-xl-12">
                        <div className="row">
                            {state.people &&
                                state.people.map((value, index) => (
                                    <div
                                        key={index}
                                        className="col-md-4 col-sm-6 pe-2 ps-2"
                                    >
                                        <div className="card d-block shadow-xss rounded-xxl overflow-hidden mb-3">
                                            <div className="card-body d-block w-100 p-4 text-center">
                                                <figure className="avatar ms-auto me-auto mb-0 position-relative w90 z-index-1">
                                                    <img
                                                        src={
                                                            value.imageUrl
                                                                ? value.imageUrl
                                                                : defaultProfilePic
                                                        }
                                                        className={`float-right p-1 bg-white rounded-circle w-100 ${
                                                            authenticated
                                                                ? ""
                                                                : "image-blur"
                                                        }`}
                                                        style={{
                                                            minHeight: "90px",
                                                            maxHeight: "90px",
                                                            objectFit: "cover",
                                                        }}
                                                    />
                                                </figure>

                                                <div className="clearfix"></div>
                                                <h4 className="fw-700 font-xss mt-3 mb-0">
                                                    {authenticated
                                                        ? value.handle
                                                        : abbreviateName(
                                                              value.handle
                                                          )}{" "}
                                                </h4>
                                                <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">
                                                    {value.headline}
                                                </p>
                                                <ul className="d-flex align-items-center justify-content-center mt-1">
                                                    {value.resumeUrl ? (
                                                        <li className="m-1">
                                                            <a
                                                                onClick={() =>
                                                                    authenticated &&
                                                                    followLink(
                                                                        value.resumeUrl
                                                                    )
                                                                }
                                                                href={
                                                                    authenticated
                                                                        ? "#"
                                                                        : "/pricing"
                                                                }
                                                                className={`btn-round-md ms-2 rounded-4 pointer ${
                                                                    authenticated
                                                                        ? "bg-mini-gradient"
                                                                        : "bg-greylight"
                                                                }`}
                                                            >
                                                                <i
                                                                    className={`feather-file-text font-xs ${
                                                                        authenticated
                                                                            ? "text-white"
                                                                            : "text-grey-500"
                                                                    }`}
                                                                ></i>
                                                            </a>
                                                            <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                                                                Resume
                                                            </span>
                                                        </li>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {value.website ? (
                                                        <li className="m-1">
                                                            <a
                                                                onClick={() =>
                                                                    authenticated &&
                                                                    followLink(
                                                                        value.website
                                                                    )
                                                                }
                                                                href={
                                                                    authenticated
                                                                        ? "#"
                                                                        : "/pricing"
                                                                }
                                                                className={`btn-round-md ms-2 rounded-4 pointer ${
                                                                    authenticated
                                                                        ? "bg-blue-gradient"
                                                                        : "bg-greylight"
                                                                }`}
                                                            >
                                                                <i
                                                                    className={`feather-globe font-xs ${
                                                                        authenticated
                                                                            ? "text-white"
                                                                            : "text-grey-500"
                                                                    }`}
                                                                ></i>
                                                            </a>
                                                            <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                                                                Website
                                                            </span>
                                                        </li>
                                                    ) : (
                                                        ""
                                                    )}
                                                </ul>
                                                {/* <a
                                                    href="#"
                                                    className="mt-4 p-0 btn p-2 lh-24 w100 ms-1 ls-3 d-inline-block rounded-xl font-xsssss fw-700 ls-lg text-white"
                                                    style={{
                                                        backgroundColor:
                                                            "black",
                                                    }}
                                                >
                                                    CONNECT
                                                </a> */}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            {state.people && state.people.length === 1 && (
                                <div className="w400" />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {props.withGraphic && state.people && state.people.length === 0 && (
                <ImageContainer>
                    <ImageGraphic src={coverGraphic} />
                </ImageContainer>
            )}
        </>
    );
};

const ImageGraphic = styled.img`
    opacity: 0.65;
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    object-fit: cover;
`;

const Title = styled.span`
    @media screen and (max-width: 436px) {
        display: none;
    }
`;

const TitleIcon = styled.i`
    display: none;

    @media screen and (max-width: 436px) {
        display: inline;
    }
`;

SearchList.propTypes = {
    withGraphic: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps, null)(SearchList);
