import { Component } from "react";
import { connect } from "react-redux";
import Leftside from "../components/layout/Leftside";
import Rightside from "../components/layout/Rightside";
import MainSection from "../components/layout/MainSection";
import { getPosts } from "../redux/actions/dataActions";
import { PropTypes } from "prop-types";
import styled from "styled-components";
import Navbar from "../components/layout/Navbar";

class Home extends Component {
    componentDidMount() {
        this.props.getPosts();
    }

    render() {
        return (
            <>
                <Navbar />
                <Container className="main-content">
                    <Layout>
                        <Leftside />
                        <MainSection />
                        <Rightside />
                    </Layout>
                </Container>
            </>
        );
    }
}

const Container = styled.div`
    padding-top: 68px;
    padding-left: 16px !important;
    padding-right: 16px !important;
    padding-bottom: 20px !important;
    height: 100% !important;
`;

const Layout = styled.div`
    display: grid;
    grid-template-areas: "leftside main rightside";
    grid-template-columns: minmax(0, 5fr) minmax(0, 12fr) minmax(300px, 7fr);
    column-gap: 25px;
    row-gap: 25px;
    margin: 25px 0;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        padding: 0 5px;
    }
`;

Home.propTypes = {
    getPosts: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    data: state.data,
});

export default connect(mapStateToProps, { getPosts })(Home);
