import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

// UI Components
import PostView from "../post/PostView";
import PostModal from "../post/PostModal";
import LoadingBar from "../../util/LoadingBar";

// Redux
import { getPosts } from "../../redux/actions/dataActions";

export class MainSection extends Component {
    componentDidMount() {
        this.props.getPosts();
    }

    constructor() {
        super();
        this.state = {
            showModal: false,
            assetArea: "text",
        };
    }

    handleClick = (event) => {
        event.preventDefault();
        this.setState({
            showModal: this.state.showModal ? false : true,
        });
    };

    render() {
        dayjs.extend(relativeTime);
        const { posts, loading } = this.props.data;
        const authenticated = this.props.user.authenticated;
        const profilePhoto = this.props.user.credentials.imageUrl;
        return (
            <Container>
                {authenticated && (
                    <>
                        <div className="card w-100 shadow-xss rounded-xxl ps-4 pt-4 pe-4 pb-3 mb-3">
                            <div className="card-body p-0">
                                <a
                                    onClick={this.handleClick}
                                    disabled={loading ? true : false}
                                    className="font-xssss fw-600 text-grey-500 card-body p-0 d-flex align-items-center"
                                >
                                    <i className="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight" />
                                    <PostListTitle>Create Post</PostListTitle>
                                </a>
                            </div>
                            <div className="card-body p-0 mt-3 position-relative">
                                <ProfilePic className="avatar position-absolute ms-2 mt-1 top-5">
                                    <img
                                        src={profilePhoto ?? "images/user.svg"}
                                        alt="icon"
                                        className="shadow-sm rounded-circle w30"
                                    />
                                </ProfilePic>
                                <PostButton className="theme-dark-bg border-light-md">
                                    <button
                                        onClick={this.handleClick}
                                        disabled={loading ? true : false}
                                        className="text-grey-500 font-xssss"
                                    >
                                        New post . . .
                                    </button>
                                </PostButton>
                            </div>
                            <div className="card-body d-flex p-0 mt-0">
                                <a
                                    className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"
                                    onClick={(e) => {
                                        this.setState(
                                            { assetArea: "media" },
                                            () => {
                                                this.handleClick(e);
                                            }
                                        );
                                    }}
                                >
                                    <i className="font-md text-danger feather-video me-2" />
                                    <span className="d-none-xs">Video</span>
                                </a>
                                <a
                                    className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"
                                    onClick={(e) => {
                                        this.setState(
                                            { assetArea: "image" },
                                            () => {
                                                this.handleClick(e);
                                            }
                                        );
                                    }}
                                >
                                    <i className="font-md text-success feather-image me-2" />
                                    <span className="d-none-xs">Photo</span>
                                </a>
                                <a
                                    className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"
                                    onClick={(e) => {
                                        this.setState(
                                            { assetArea: "text" },
                                            () => {
                                                this.handleClick(e);
                                            }
                                        );
                                    }}
                                >
                                    <i className="font-md text-warning feather-file-text me-2" />
                                    <span className="d-none-xs">Text</span>
                                </a>
                            </div>
                        </div>
                    </>
                )}
                <>
                    {!posts || (posts.length === 0 && !loading) ? (
                        <p className="text-dark">Nothing to show 😒</p>
                    ) : (
                        <Content>
                            <PostListTitle>Recent Activity</PostListTitle>
                            {loading && (
                                <>
                                    <LoadingBar />
                                    <div style={{ height: "2500px" }} />
                                </>
                            )}

                            {this.props.UI.loading && <LoadingBar />}

                            {posts.length > 0 &&
                                posts.map((post, index) => (
                                    <PostView
                                        key={index}
                                        postId={post.postId}
                                        mediaVideo={post.mediaVideo ?? ""}
                                        mediaImage={post.mediaImage ?? ""}
                                        userImage={
                                            post.userImage ?? "images/user.svg"
                                        }
                                        userHandle={post.userHandle}
                                        userId={post.userId}
                                        createdAt={dayjs(
                                            new Date(
                                                post.createdAt._seconds * 1000
                                            ).toLocaleString()
                                        ).fromNow()}
                                        body={post.body}
                                        commentCount={post.commentCount}
                                        likeCount={post.likeCount}
                                        dislikeCount={post.dislikeCount}
                                    />
                                ))}
                        </Content>
                    )}
                </>
                <PostModal
                    showModal={this.state.showModal}
                    handleClick={this.handleClick}
                    initialAssetArea={this.state.assetArea}
                />
            </Container>
        );
    }
}

const Container = styled.div`
    grid-area: main;
    height: 100%;
    div {
        div {
            a {
                cursor: pointer;
            }
        }
    }
`;

const PostButton = styled.div`
    display: flex;
    text-align: center;
    overflow: hidden;
    margin-bottom: 16px;
    background-color: #fff;
    border-radius: 100px;
    postion: relative;
    border: none;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 15%), 0 0 0 rgb(0 0 0 / 20%);
    button {
        outline: none;
        line-height: 1.5;
        width: 100%;
        margin-left: 48px;
        min-height: 48px;
        background: transparent;
        border: none;
        font-weight: 500;
        display: flex;
        align-items: center;
    }
`;

const Content = styled.div`
    text-align: center;
    & > img {
        width: 72px;
    }
`;

const PostListTitle = styled.h4`
    text-align: left;
    font-size: 14px;
    font-weight: 700;
`;

const ProfilePic = styled.figure`
    img {
        width: 30px;
        height: 30px;
        object-fit: cover;
    }
`;

MainSection.propTypes = {
    getPosts: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    data: state.data,
    user: state.user,
    UI: state.UI,
});

export default connect(mapStateToProps, { getPosts })(MainSection);
