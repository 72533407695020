import { React, Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { roles } from "../../util/searchData";

// UI Components
import StringAutoComplete from "../../util/StringAutoComplete";
import progressSpinner from "../../images/spin-loader.svg";
import JobView from "./JobView";

// Redux
import { connect } from "react-redux";
import { getJobs } from "../../redux/actions/dataActions";

class JobSearch extends Component {
    constructor() {
        super();
        this.state = {
            query: "",
            location: "",
        };
    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const queryDetails = {
            search: this.state.query,
            location: this.state.location,
        };
        this.props.getJobs(queryDetails);
    };

    render() {
        dayjs.extend(relativeTime);

        const jobs = this.props.data.jobs;

        return (
            <>
                <SearchCard className="card w-100 bg-white shadow-xss rounded-xxl">
                    <h4 className="fw-700 font-sm mt-2 mb-3">Job Search</h4>
                    <InputWrapperColumn>
                        <InputContainer>
                            <label>
                                <h5>
                                    <i className="feather-search" />
                                    {"  "}Search
                                </h5>
                            </label>
                            <div className="card p-0 bg-white shadow-xss rounded-xxl">
                                <StringAutoComplete
                                    name="query"
                                    onChange={this.handleChange}
                                    suggestions={roles}
                                    placeholder="company, job, role, etc."
                                    labelFromHit={(hit) => hit}
                                    lowercase={true}
                                />
                            </div>
                        </InputContainer>
                        <form id="job-search" onSubmit={this.handleSubmit}>
                            <InputContainer>
                                <label>
                                    <h5>
                                        <i className="feather-map-pin" />
                                        {"  "}Location
                                    </h5>
                                </label>
                                <div className="card p-0 bg-white shadow-xss rounded-xxl">
                                    <input
                                        type="text"
                                        name="location"
                                        className="bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg"
                                        onChange={this.handleChange}
                                        placeholder="city, state, zip, etc"
                                    />
                                </div>
                            </InputContainer>
                            <SubmitButton
                                disabled={this.props.data.loading}
                                type="submit"
                                className="d-none"
                            >
                                {this.props.data.loading ? (
                                    <img src={progressSpinner} alt="loading" />
                                ) : (
                                    "Search"
                                )}
                            </SubmitButton>
                        </form>
                    </InputWrapperColumn>
                    <InputWrapperRow>
                        <InputContainer>
                            <label>
                                <h5>
                                    <i className="feather-search" />
                                    {"  "}Search
                                </h5>
                            </label>
                            <div className="card p-0 bg-white shadow-xss rounded-xxl">
                                <StringAutoComplete
                                    name="query"
                                    onChange={this.handleChange}
                                    suggestions={roles}
                                    placeholder="company, job, role, etc."
                                    labelFromHit={(hit) => hit}
                                    lowercase={true}
                                />
                            </div>
                        </InputContainer>
                        <form
                            id="job-search"
                            onSubmit={this.handleSubmit}
                            className="me-4"
                        >
                            <InputContainer>
                                <label>
                                    <h5>
                                        <i className="feather-map-pin" />
                                        {"  "}Location
                                    </h5>
                                </label>
                                <div className="card p-0 bg-white shadow-xss rounded-xxl">
                                    <input
                                        type="text"
                                        name="location"
                                        className="bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg"
                                        onChange={this.handleChange}
                                        placeholder="city, state, zip, etc"
                                    />
                                </div>
                            </InputContainer>
                            <SubmitButton
                                disabled={this.props.data.loading}
                                type="submit"
                                className="d-none"
                            >
                                {this.props.data.loading ? (
                                    <img src={progressSpinner} alt="loading" />
                                ) : (
                                    "Search"
                                )}
                            </SubmitButton>
                        </form>
                    </InputWrapperRow>
                    <SubmitButton
                        disabled={this.props.data.loading}
                        type="button"
                        onClick={this.handleSubmit}
                    >
                        {this.props.data.loading ? (
                            <img src={progressSpinner} alt="loading" />
                        ) : (
                            "Search"
                        )}
                    </SubmitButton>
                </SearchCard>
                <div>
                    <h4 className="fw-700">Results</h4>
                    {!(jobs && jobs.length) && (
                        <div className="w-100" style={{ height: "370px" }}>
                            <p className="text-center text-dark">
                                Nothing to show
                            </p>
                        </div>
                    )}
                    {jobs.map((job, index) => {
                        const color =
                            index % 3 === 0
                                ? "text-success alert-success"
                                : index % 3 === 1
                                ? "text-warning alert-warning"
                                : index % 3 === 2
                                ? "text-secondary alert-secondary"
                                : "";
                        return <JobView job={job} key={index} color={color} />;
                    })}
                </div>
            </>
        );
    }
}

const SearchCard = styled.div`
    padding-left: 24px;
    padding-bottom: 24px;
    padding-right: 24px;
`;

const InputContainer = styled.div`
    width: 100%;
    margin-bottom: 24px;
    max-height: 70px;
    z-index: 1;
`;

const SubmitButton = styled.button`
    justify-content: center;
    color: #ffffff;
    background-color: #000000;
    align-items: center;
    height: 56px;
    width: 100%;
    border-radius: 28px;
    box-shadow: inset 0 0 0 1px rgb(0 0 0 / 60%),
        inset 0 0 0 2px rgb(0 0 0 / 0%) inset 0 0 0 1px rgb(0 0 0 / 0);
    vertical-align: middle;
    z-index: 0;
    transition-duration: 167ms;
    font-size: 20px;
    img {
        width: 36px;
        height: 36px;
    }
    &:hover {
        background-color: rgba(207, 207, 207, 0.25);
        color: rgba(0, 0, 0, 0.75);
    }
`;

const InputWrapperColumn = styled.div`
    @media screen and (min-width: 600px) {
        display: none;
    }
    @media screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
    }
`;

const InputWrapperRow = styled.div`
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 50% 50%;

    @media screen and (max-width: 600px) {
        display: none;
    }
`;

JobSearch.propTypes = {
    data: PropTypes.object.isRequired,
    getJobs: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    data: state.data,
});

export default connect(mapStateToProps, { getJobs })(JobSearch);
