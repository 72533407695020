import { connect } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ThemeToggle from "./ThemeToggle";
import { logoutUser } from "../../redux/actions/userActions";
import PropTypes from "prop-types";
import NavDrawer from "./NavDrawer";
import NavDrawerMenu from "./NavDrawerMenu";
import { toAddress } from "../../util/companyAddress";

const Navbar = (props) => {
    const {
        authenticated,
        credentials: { handle, userId },
    } = props.user;

    const path = window.location.pathname;
    const profileUrl = `/companies/${toAddress(handle, userId)}`;

    return (
        <Container>
            <Content>
                <NavDrawer>
                    <NavDrawerMenu user={props.user} />
                </NavDrawer>
                <Logo>
                    <Link to={`${authenticated ? "/search" : "/"}`}>
                        <img
                            src="/images/blynk_gold.png"
                            style={{ maxWidth: "65vw", maxHeight: "75px" }}
                            alt=""
                        />
                    </Link>
                </Logo>

                <Nav>
                    <NavListWrap>
                        {authenticated ? (
                            <>
                                <Search
                                    className={`${
                                        path == "/search" ? "active" : ""
                                    }`}
                                >
                                    <a href="/search" className="nav-icons">
                                        <i className="feather-search font-lg" />
                                        <span>Search</span>
                                    </a>
                                </Search>

                                <NavListItem
                                    className={`${
                                        path.startsWith("/companies") ? "active" : ""
                                    }`}
                                >
                                    <a href={profileUrl} className="nav-icons">
                                        <i className="feather-user font-lg" />
                                        <span>My Company</span>
                                    </a>
                                </NavListItem>

                                <Events className={`${path === "/events"? "active": ""} d-none-xs`}>
                                    <a href="/events" className="nav-icons">
                                        <i className="feather-calendar font-lg" />
                                        <span>Events</span>
                                    </a>
                                </Events>
                            </>
                        ) : (
                            <>
                                <NavListItem className="d-none-xs">
                                    <Link to="/login" className="nav-icons">
                                        <i className="feather-log-in font-lg" />
                                        <span>Login</span>
                                    </Link>
                                </NavListItem>

                                <NavListItem className="d-none-xs">
                                    <Link to="/sign-up" className="nav-icons">
                                        <i className="feather-user-plus font-lg" />
                                        <span>Sign Up</span>
                                    </Link>
                                </NavListItem>

                                <Search
                                    className={`${
                                        path === "/search" ? "active" : ""
                                    }`}
                                >
                                    <a href="/search" className="nav-icons">
                                        <i className="feather-search font-lg" />
                                        <span>Search</span>
                                    </a>
                                </Search>
                            </>
                        )}

                        <NavListItem>
                            {path !== "/event/create" && <ThemeToggle />}
                        </NavListItem>
                    </NavListWrap>
                </Nav>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    background-color: black;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    bottom-margin: 24px;
    left: 0;
    padding: 0 24px;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 100;
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    margin: 0 auto;
    min-height: 100%;
    max-width: 1128px;
`;

const Logo = styled.span`
    margin-right: 8px;
    font-size: 0px;
`;

const Nav = styled.nav`
    margin-left: auto;
    display: block;
    @media (max-width: 768px) {
        position: fixed;
        left: 0;
        bottom: 0;
        background: white;
        width: 100%;
    }
`;

const NavListWrap = styled.ul`
    display: flex;
    flex-wrap: nowrap;
    list-style-type: none;
    .active {
        span:after {
            content: "";
            transform: scaleX(1);
            border-bottom: 2px solid var(--white, #fff);
            bottom: 0;
            left: 0;
            position: absolute;
            transition: transform 0.2s ease-in-out;
            width: 100%;
            border-color: #afb7be;
        }
    }
`;

const NavListItem = styled.li`
    display: flex;
    align-items: center;
    a {
        align-items: center;
        background: transparent;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        font-weight: 400;
        justify-content: center;
        line-height: 1.5;
        min-height: 52px;
        min-width: 80px;
        position: relative;
        text-decoration: none;
        span {
            // color: #AFB7BE;
            display: flex;
            align-items: center;
        }
        @media (max-width: 768px) {
            min-width: 70px;
        }
    }
`;

const SignOut = styled.div`
    position: absolute;
    z-index: 100;
    top: 45px;
    background: white;
    border-radius: 0 0 5px 5px;
    width: 100px;
    height: 40px;
    font-size: 16px;
    transition-duration: 167ms;
    text-align: center;
    display: none;
    a {
        cursor: pointer;
        color: #000000;
    }
`;

const User = styled(NavListItem)`
    a > svg {
        width: 24px;
        border-radius: 50%;
    }
    a > img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid #afb7be;
    }
    span {
        display: flex;
        align-items: center;
    }
    &:hover {
        ${SignOut} {
            align-items: center;
            display: flex;
            justify-content: center;
        }
    }
`;

const Search = styled(User)`
    margin-right: 1rem;
`;

const Events = styled(User)`
    margin-right: 1rem;
`;

Navbar.propTypes = {
    user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps, { logoutUser })(Navbar);
