import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { conceal } from "../../util/cryptography";
import { Link } from "react-router-dom";

// UI Components
import EventDetailsButton from "./EventDetailsButton";
import defaultBanner from "../../images/town.svg";

const EventView = (props) => {
    const time = new Date(props.event.start._seconds * 1000);

    const generateQueryString = () => {
        const eventDetails = {
            meetingNumber: props.event.meetingNumber,
            handle: props.user.credentials.handle,
            email: props.user.credentials.email,
            password: props.event.password ?? "password",
            roleName: props.event.company.userId === props.user.credentials.userId? "host": "participant",
            darkBackground: props.event.darkBackground,
            headerLogoUrl: props.event.company.headerLogoUrl,

        };
        const eventDetailsString = JSON.stringify(eventDetails);
        return `?d=${conceal(eventDetailsString)}`;
    };

    return (
        <div className="ps-2 pe-2 col-md-4 col-sm-6">
            <div className="card p-3 bg-white hover-card border-0 shadow-xss rounded-xxl border-0 mb-3 overflow-hidden pe-2 ps-2 h400">
                <div className="card-image">
                    <img
                        src={
                            props.event.bannerUrl ? props.event.bannerUrl: defaultBanner
                        }
                        className="rounded-3 bg-grey"
                        style={{height: "215px", width: "100%"}}
                    />
                </div>
                <div className="card-body d-flex ps-0 pe-0 pb-0">
                    <div className="bg-greylight me-3 p-3 border-light-md rounded-xxl theme-dark-bg">
                        <h4 className="fw-700 font-lg ls-3 text-grey-900 mb-0">
                            <span className="ls-3 d-block font-xsss text-grey-500 fw-500 text-uppercase">
                                {time.toLocaleString("default", {
                                    month: "short",
                                })}
                            </span>
                            {time.getDate()}
                        </h4>
                    </div>
                    <h2 className="fw-700 lh-3 font-xss">
                        {props.event.eventTitle}
                        <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                            {" "}
                            <i className="feather-user me-2"></i>
                            {props.event.company.companyName}{" "}
                        </span>
                    </h2>
                </div>
                <img
                    className="position-absolute w50 ms-2 mt-2 rounded-circle"
                    src={props.event.company.imageUrl ?? ""}
                />
                <div className="bg-transparent position-absolute w-100 pe-4 pt-2">
                    <EventDetailsButton event={props.event} />                
                </div>
                <div className="card-body p-0">
                    <Link
                        to={props.user.authenticated? `/events/${props.event.meetingNumber}${generateQueryString()}`: "/login"}
                        className={`font-xsss fw-700 ps-3 pe-3 lh-32 float-right mt-4 rounded-xxl ls-2 bg-black d-inline-block text-white me-1 ${props.user.authenticated? "bg-black text-white": "bg-greylight text-grey-500"}`}
                    >
                        Join Event
                    </Link>
                </div>
            </div>
        </div>
    );
};

EventView.propTypes = {
    event: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps, null)(EventView);
