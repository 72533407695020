import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
import { uploadImage } from "../../redux/actions/userActions";

class EditProfilePicButton extends Component {
    handleImageChange = (event) => {
        const image = event.target.files[0];
        const formData = new FormData();
        formData.append("image", image, image.name);
        this.props.uploadImage(formData);
    };

    handleEditPicture = () => {
        const fileInput = document.getElementById("imageInput");
        fileInput.click();
    };

    render() {
        return (
            <div>
                <input 
                    type="file"
                    id="imageInput"
                    hidden="hidden"
                    onChange={this.handleImageChange}
                />
                <a onClick={this.handleEditPicture}>
                    <i className="btn-round-xs font-xss text-grey-900 feather-edit-2 bg-greylight pointer" />
                </a>                
            </div>
        );
    }
}

EditProfilePicButton.propTypes = {
    uploadImage: PropTypes.func.isRequired
};

export default connect(null, { uploadImage })(EditProfilePicButton);
