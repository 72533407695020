import React, { Component, Fragment } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Redux
import { createComplaint } from "../../redux/actions/dataActions";

class ComplaintButton extends Component {
    constructor() {
        super();
        this.state = {
            showModal: false,
            complaintType: "",
            comments: ""
        };
    }

    handleOpen = () => {
        this.setState({ showModal: true });
    };

    handleClose = () => {
        this.setState({
            showModal: false,
            complaintType: "",
            comments: ""
        });
    };

    handleChange = (event) => {
        event.preventDefault();
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleRadioChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleSubmit = () => {
        if (this.state.complaintType !== "") {
            const complaintDetails = {
                comments: this.state.comments,
                complaint: this.state.complaintType
            };
            if (this.props.user.authenticated) {
                complaintDetails.email = this.props.user.credentials.email;
            }
            this.props.createComplaint(complaintDetails);            
            this.handleClose();
        }
    };

    render() {
        const complaintTypes = [
            "Spam Content",
            "Misinformation & Impersonation",
            "Illegal Activities",
            "Sexual Content, Nudity & Obscenity",
            "Bullying & Harassment",
            "Discrimination & Racism",
            "Violence, Threats & Self-harm",
            "Other"
        ];

        return (
            <Fragment>
                <a onClick={this.handleOpen} className="card-body d-flex pt-0 pointer">
                    <i className="feather-flag text-grey-500 me-3 font-lg"/>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-1">Report Issue </h4>
                </a>
                {this.state.showModal && (
                    <Container>
                        <Content className="card w-100 shadow-xss rounded-xxl">
                            <Header className="shadow-xss">
                                <h2 style={{ whiteSpace: "nowrap" }}>
                                    Report Issue
                                </h2>
                                <CancelButtonArea className="card-body p-0">
                                    <a
                                        className="font-xssss fw-600 text-warning-500"
                                        onClick={this.handleClose}
                                    >
                                        <i
                                            className="btn-round-sm font-xs text-danger feather-x me-2 bg-danger-light"
                                            onClick={this.handleClose}
                                        />
                                        <br />
                                    </a>
                                </CancelButtonArea>
                            </Header>
                            <hr
                                style={{
                                    width: "100%",
                                    margin: 0,
                                    padding: 0,
                                    backgroundColor: "#adb5bd",
                                }}
                            />
                            <div style={{overflowY: "scroll"}}>
                                <ComplaintRadioContainer onChange={this.handleRadioChange}>
                                    <label>
                                        <h5>Problem</h5>
                                    </label>
                                    <br/>
                                    {
                                        complaintTypes.map((complaintType, index) => {
                                            return (
                                                <Fragment key={index}>
                                                    <span
                                                        className="fw-500 text-dark font-xssss"
                                                    >
                                                        <input
                                                            className="me-4 pointer"
                                                            type="radio"
                                                            name="complaintType"
                                                            value={complaintType}
                                                        />
                                                        {complaintType}
                                                    </span>
                                                    <br/>
                                                </Fragment>
                                            );
                                        })
                                    }
                                </ComplaintRadioContainer>                          
                                <Editor>
                                    <div className="card-body p-0 mt-3 position-relative">
                                        <label>
                                            <h5>Comments</h5>
                                        </label>
                                        <textarea
                                            name="comments"
                                            className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg"
                                            cols="30"
                                            rows="10"
                                            placeholder="Please add anything else about your situation, including relevant account details"
                                            value={this.state.comments}
                                            onChange={this.handleChange}
                                            autoFocus={true}
                                        />                               
                                    </div>
                                </Editor>
                            </div>
                            <BottomButtonArea>
                                <a
                                    className={`p-2 lh-20 w100 ${this.state.complaintType === ""? "bg-greylight text-grey-500": "bg-gold-gradient text-white"} me-2 text-center font-xssss fw-600 ls-1 rounded-xl`}
                                    onClick={this.handleSubmit}
                                >
                                    <i className={`font-xs ${this.state.complaintType === ""? "text-grey-500": "text-white"} feather-send me-2 bg-transparent`}/>
                                    Send
                                </a>
                            </BottomButtonArea>
                        </Content>
                    </Container>
                )}
            </Fragment>
        );
    }
}

const Editor = styled.div`
    padding: 12px 24px;
    textarea {
        width: 100%;
        min-height: 100px;
        resize: none;
    }
    input {
        width: 100%;
        height: 35px;
        font-size: 16px;
        margin-bottom: 20px;
    }
`;

const BottomButtonArea = styled.div`
    padding: 12px 24px 12px 16px;
    a {
        float: right;
        cursor: pointer;
    }
`;

const CancelButtonArea = styled.div`
    a {
        color: red;
        float: right;
        font-weight: 500;
        cursor: pointer;
    }
`;

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    color: black;
    background-color: rgba(0, 0, 0, 0.8);
    animation: fadeIn 0.3s;
`;

const Header = styled.div`
    display: block;
    padding: 16px 20px;
    font-size: 14px;
    line-height: 1.5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
        font-weight: 700;
        color: black;
    }
    button {
        height: 40px;
        width: 40px;
        min-width: auto;
        color: rgba(0, 0, 0, 0.15);
        svg,
        img {
            pointer-events: none;
        }
    }
`;

const Content = styled.div`
    width: 100%;
    max-width: 552px;
    background-color: white;
    max-height: 90%;
    overflow: initial;
    border-radius: 12px;
    position: relative;
    display: flex;
    flex-direction: column;
    top: 32px;
    margin: 0 auto;
`;

const ComplaintRadioContainer = styled.div`
    padding: 12px 24px;
`;

ComplaintButton.propTypes = {
    user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, { createComplaint })(ComplaintButton);
