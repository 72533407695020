import { React, Component } from "react";
import PropTypes from "prop-types";

// UI Components
import EventView from "./EventView";
import LoadingBar from "../../util/LoadingBar";
import { Link } from "react-router-dom";

// Redux
import { getEvents } from "../../redux/actions/dataActions";
import { connect } from "react-redux";

class EventList extends Component {
    componentDidMount() {
        this.props.getEvents();
    }

    render() {
        return (
            <>
                <div className="middle-sidebar-left ps-3">
                    <div className="row ps-2 pe-2">
                        <div className="card shadow-xss w-100 h75 d-block p-4 mb-2 rounded-xxl">
                            <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center">
                                {"Events "}
                            </h2>
                        </div>
                        <Link
                            to="/event/create"
                            className="pointer text-facebook rounded-xxxl font-xs fw-600"
                        >
                            <i className="feather-plus me-2" />
                            Create Event
                        </Link>
                        <div className="col-xl-12">
                            <div className="row">
                                {this.props.loading && <LoadingBar />}
                                {!this.props.loading &&
                                    this.props.events.map((Event, index) => {
                                        return (
                                            <EventView
                                                key={index}
                                                event={Event}
                                            />
                                        );
                                    })}
                                {this.props.events.length === 1 && (
                                    <div className="w400" />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

EventList.propTypes = {
    events: PropTypes.object.isRequired,
    getEvents: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    events: state.data.events,
    loading: state.data.loading,
});

export default connect(mapStateToProps, { getEvents })(EventList);
