export const toAddress = (handle, uid) => {
  if (handle && uid) {
    return `${handle.replace(" ", "-").toLowerCase()}-${uid.slice(0, 8)}`;  
  }
};

export const fromAddress = (address) => { // Assumes an uppercase handle;
  const fragments = address.split("-");
  const uidPrefix = fragments[fragments.length - 1].trim();
  const handle = fragments
    .slice(0, fragments.length - 1)
    .join(" ")
    .replace(/\b\w/g, l => l
    .toUpperCase())
    .trim();
  return { handle, uidPrefix };
}; 

export const successorKeyFromQuery = (query) => {
  let result = query.slice(0, query.length - 1);
  result += String.fromCharCode(query[query.length - 1].charCodeAt() + 1);
  return result;
};