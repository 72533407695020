import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

// UI Components
import Rightside from "../components/layout/Rightside";
import Navbar from "../components/layout/Navbar";
import CompanyContent from "../components/companies/CompanyContent";
import LoadingBar from "../util/LoadingBar";
import CommentModal from "../components/post/CommentModal";

// Redux
import { connect } from "react-redux";
import { getCompanyData } from "../redux/actions/dataActions";
import { getCompanyProfileContent } from "../redux/actions/dataActions";

class Company extends Component {
    state = {
        postIdParam: null,
        openPost: false
    };

    componentDidMount() {
        const address = window.location.pathname.slice(11, window.location.pathname.length).split("/")[0];
        const postId = window.location.pathname.split("/")[4];

        if (postId) {
            this.setState({
                postIdParam: postId,
                openPost: true
            });
        }
        this.props.getCompanyProfileContent(address);  
    }

    handleClick = (event) => {
        event.preventDefault();
        this.setState({openPost: this.state.openPost? false: true});
    };

    render() {
        dayjs.extend(relativeTime);
        const { posts, loading } = this.props.data;
        const { postIdParam } = this.state;

        return (
            <Container className="main-content right-chat-active">
                <Navbar />
                <Layout>
                    <div>
                        { loading? (
                            <>
                                <LoadingBar/>
                                <div style={{height: "2500px"}}/>
                            </>
                        ) : (
                            <>
                                <CommentModal
                                    showModal={this.state.openPost}
                                    handleClick={this.handleClick}
                                    postId={this.state.postIdParam}
                                    userHandle={this.props.data.profile.handle}
                                />
                                <CompanyContent
                                    posts={posts}
                                    openPost={postIdParam? true: false}
                                />
                            </>
                        )}
                    </div>
                    <Rightside />
                </Layout>
            </Container>
        );
    }
}

const Container = styled.div`
    padding-top: 68px;
    max-width: 100%;
    min-height: 100vh;
    padding-left: 16px !important;
    padding-right: 16px !important;
    padding-bottom: 110px;
`;

const Layout = styled.div`
    display: grid;
    grid-template-areas: "main rightside";
    grid-template-columns: minmax(0, 12fr) minmax(300px, 7fr);
    column-gap: 25px;
    row-gap: 25px;
    margin: 25px 0;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        padding: 0 5px;
    }
`;

Company.propTypes = {
    getCompanyData: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    data: state.data,
});

const mapActionsToProps = {
    getCompanyData,
    getCompanyProfileContent
};

export default connect(mapStateToProps, mapActionsToProps)(Company);
