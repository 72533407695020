import styled from "styled-components";
import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import PaymentForm from "../components/billing/PaymentForm";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ErrorBoundary from "../util/ErrorBoundary";

const stripePromise = loadStripe(
    "pk_test_51LG5B6IjRE1kjMBF9uqcg83B2V2cdESsMlHl48oI9b8Lb9JfpDmokRuXF9mCErpfGRXNfNRtD25tAvjed9oZNttD00xJWDpwnR"
);
class Payment extends Component {
    render() {
        const appearance = {
            theme: "stripe",

            variables: {
                colorPrimary: "#fbaf03",
                colorText: "#30313d",
                colorDanger: "#E50202",
                fontFamily: "Montserrat, sans-serif",
            },
        };

        // Enforce light theme
        const body = document.body;
        body.classList.replace("theme-dark", "theme-light");

        return (
            <Container>
                <Fragment>
                    <div className="main-wrap">
                        <div className="row">
                            <div
                                className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
                                style={{
                                    backgroundImage: `url("https://images.unsplash.com/photo-1641574003327-aebaf68f4b9a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80")`,
                                }}
                            >
                                <Link to="/">
                                    <img
                                        src="images/blynk_gold.png"
                                        width="370"
                                        alt="logo"
                                        style={{
                                            marginTop: "8px",
                                            marginLeft: "32px",
                                        }}
                                    />
                                </Link>
                            </div>
                            <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                                <div className="card shadow-none border-0 ms-auto me-auto login-card">
                                    <div className="card-body rounded-0 text-left">
                                        <h2 className="fw-700 display1-size display2-md-size mb-3">
                                            BLYNK Enterprise
                                            <br />
                                            Billing Setup
                                        </h2>
                                        <ErrorBoundary>
                                            <Elements
                                                stripe={stripePromise}
                                                options={{
                                                    clientSecret:
                                                        this.props.user
                                                            .credentials
                                                            .client_secret,
                                                    appearance,
                                                }}
                                            >
                                                <PaymentForm />
                                            </Elements>
                                        </ErrorBoundary>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            </Container>
        );
    }
}

const Container = styled.div`
    padding: 0px;
`;

Payment.propTypes = {
    user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps, null)(Payment);
