import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import axios from "axios";
import progressSpinner from "../../images/spin-loader.svg";
import mixpanel from "../../util/mixpanel";

class JobView extends Component {
    constructor() {
        super();
        this.state = {
            showModal: false
        };
    }

    closeModal = () => {
        this.setState({showModal: false});
    };

    goToJob = (redirectUrl) => {
        this.setState({ showModal: true});
        axios.post("/job-url", { redirectUrl })
            .then((res) => {
                window.open(res.data.url, "_blank", "noreferrer,noopener");
                this.setState({showModal: false});
            });   
    };


    render() {
        const {
            title,
            created,
            description,
            redirect_url,
            contract_time,
        } = this.props.job;
        const location = `${this.props.job.location.area[2]}, ${this.props.job.location.area[1]}`;
        const company = this.props.job.company.display_name;
        const category = this.props.job.category.label;
        const { color } = this.props;
        dayjs.extend(relativeTime);

        return (
            <>
                { this.state.showModal && 
                    <ModalContainer>
                        <img className="card shadow-xss rounded-xxl" src={progressSpinner} alt="loading" style={{width: "175px"}}/>                       
                    </ModalContainer>
                } 
                <JobCard className="card d-block w-100 mb-3 shadow-xss bg-white rounded-xxl pe-4 pt-4 pb-4" style={{ paddingLeft: "120px" }}>
                    <Avatar className={` feather-briefcase ${color} text-center position-absolute p-2  w65  ms-4 left-0`} />
                    <h4 className="font-xss fw-700 text-grey-900 mb-3 pe-4">{title} <span className="font-xssss fw-500 text-grey-500 ms-1">{dayjs(new Date(created).toLocaleString()).fromNow()}</span> </h4>
                    <h5>{company}</h5><br />
                    <h5 className="font-xssss mb-2 text-grey-500 fw-600"><span className="text-grey-900 font-xssss text-dark">Location : </span> {location ?? "N/A"}</h5>
                    <h5 className="font-xssss mb-2 text-grey-500 fw-600"><span className="text-grey-900 font-xssss text-dark">Schedule : </span>{contract_time ?? "N/A"}</h5>
                    <h5 className="font-xssss mb-2 text-grey-500 fw-600"><span className="text-grey-900 font-xssss text-dark">Category : </span>{category ?? "N/A"}</h5>

                    <p className="fw-500 text-black-500 lh-26 font-xssss w-100 mb-2">{description.substring(0, 180)}...</p>
                    <a
                        onClick={() => {
                            mixpanel.track("Job Click", {
                                title: title,
                                company: company,
                                location: location,
                                category: category
                            });
                            this.goToJob(redirect_url);
                        }}
                        className="float-right">
                        <i className="btn-round-sm font-xs text-grey-900 me-2 bg-greylight feather-chevron-right pointer"/>
                    </a>
                </JobCard>
            </>
        );
    }
}


JobView.propTypes = {
    job: PropTypes.object.isRequired,
    color: PropTypes.string.isRequired
};

const Avatar = styled.i`
    font-size: 42px;
    height: 65px;
`;

const JobCard = styled.div`
    width: 100%;
    overflow-y: hidden;
`;

const ModalContainer = styled.div`
    img {
        margin-left: auto;
        margin-right: auto;
        margin-top: 175px;
        display: block;
    }
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    color: black;
    background-color: rgba(0, 0, 0, 0.8);
    animation: fadeIn 0.3s;
`;

export default JobView;