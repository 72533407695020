import styled from "styled-components";
import { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import { createPost } from "../../redux/actions/dataActions";
import PropTypes from "prop-types";

/*
 * The high z-index places the modal on top of the other components,
 * while the fixed position in the top right places it such that it
 * is not bound by any containers in the browser (header, footer, etc.).
 * This allow allows the modal to be placed in front of everything in
 * the browser window.
 */

const PostModal = (props) => {
    const [body, setBody] = useState("");

    const [mediaImage, setMediaImage] = useState("");

    const [mediaVideo, setMediaVideo] = useState("");

    const { user: { credentials: { userHandle, imageUrl }}, initialAssetArea} = props;

    const [assetArea, setAssetArea] = useState(initialAssetArea);

    useEffect(() => {
        setAssetArea(props.initialAssetArea);
    }, [props.initialAssetArea]);

    const handleChange = (e) => {
        const image = e.target.files[0];

        if (image === "" || image === undefined) {
            alert(`Unsupported file type: ${typeof image}`);
            return;
        }
        setMediaImage(image);
    };

    const switchAssetArea = (area) => {
        setMediaImage("");
        setMediaVideo("");
        setAssetArea(area);
    };

    const sendPost = (e) => {
        e.preventDefault();
        if (e.target !== e.currentTarget) {
            return;
        }
        const payload = {
            body,
            userHandle,
            imageUrl,
            mediaImage,
            mediaVideo
        };
        props.createPost(payload);
        reset(e);
    };

    const reset = (e) => {
        setBody("");
        setMediaImage("");
        setMediaVideo("");
        setAssetArea("text");
        props.handleClick(e);
    };

    return (
        <>
        { props.showModal &&
        <Container>
            <Content className="card w-100 shadow-xss rounded-xxl">
                <Header className="shadow-xss">
                    <h2 style={{whiteSpace: "nowrap"}}>New Post</h2>

                    <CancelButtonArea className="card-body p-0">
                        <a className="font-xssss fw-600 text-warning-500"
                            onClick={(event) => reset(event)}>
                            <i className="btn-round-sm font-xs text-danger feather-x me-2 bg-danger-light" onClick={(event) => reset(event)}/>
                            <br/>
                        </a>
                    </CancelButtonArea>
                </Header>
                <hr
                    style={{width: "100%", margin: 0, padding: 0, backgroundColor: "#adb5bd"}}
                />
                <SharedContent>

            <Editor>
                <div className="card-body p-0 mt-3 position-relative">
                    <ProfilePic className="avatar position-absolute ms-2 mt-1 top-5">
                        <img
                            src={imageUrl ?? "images/user.svg"}
                            alt="profile"
                            className="shadow-sm rounded-circle w30"
                        />
                    </ProfilePic>
                    <textarea 
                        name="message" 
                        className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg" 
                        cols="30" 
                        rows="10" 
                        placeholder="What's on your mind?"
                        value={body}
                        onChange={(e) => setBody(e.target.value)}
                        autoFocus={true}
                    />
                        
                { assetArea === "image"?
                (<UploadImage>
                    <input 
                        type="file"
                        accept="image/gif, image/jpeg, image/png"
                        name="image"
                        id="file"
                        style={{display: "none"}}
                        onChange={handleChange}
                    />
                <p>
                    <label htmlFor="file">
                        <a className="pointer">
                            <i className="btn-round-sm font-xs text-primary pointer feather-edit-3 me-2 bg-greylight"/>
                           {" Choose an image"}
                        </a>
                    </label>
                </p>
                {mediaImage && <img src={URL.createObjectURL(mediaImage)}/>}
             
                </UploadImage>): (assetArea === "media"? (
                       <>
                       <VideoInput 
                           type="text" 
                           placeholder="Add video link"
                           value={mediaVideo}
                           onChange={(e) => setMediaVideo(e.target.value)}
                           className="bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg" 
                       />
                       {
                            mediaVideo && (
                                <>
                                    { ReactPlayer.canPlay(mediaVideo) && <ReactPlayer width={"100%"} url={mediaVideo}/> }
                                    <p className="text-dark font-xsss">
                                        <b>Don't see your video? </b>
                                        Please note that we currently only support content hosted on YouTube, Facebook, Twitch, SoundCloud, Streamable, Vimeo, Wistia, Mixcloud, DailyMotion and Kaltura.
                                    </p>
                                </>
                            )
                       }
                   </>
                ): 
                assetArea === "text" && <></>)}
                </div>
            </Editor>

        </SharedContent>


                <SharedCreation>
                    <AttachAssets>
                        <a onClick={() => switchAssetArea("media")}
                            className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4">
                                <i className="font-md text-danger feather-video me-2"/>
                                    <span className="d-none-xs">
                                        Video Link
                                    </span>
                         </a>

                         <a onClick={() => switchAssetArea("image")}
                            className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4">
                                <i className="font-md text-success feather-image me-2"/>
                                <span className="d-none-xs">
                                    Photo
                                </span>
                        </a>

                    </AttachAssets>

                    <SharedComment>
                            <a onClick={() => switchAssetArea("text")}
                                className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4">
                                    <i className="font-md text-warning feather-file-text me-2"/>
                                    <span className="d-none-xs">
                                        Text Only
                                    </span>
                            </a>
                    </SharedComment>

                    <a 
                        className={`p-2 lh-20 w100 me-2 text-center font-xssss fw-600 ls-1 rounded-xl ${body? "bg-gold-gradient text-white": "bg-greylight text-grey-500"}`}
                        onClick={(event) => (body? sendPost(event): {})}
                    >
                    <i className={`font-xs ${body? "text-white": "text-grey-500"} feather-send me-2 bg-transparent`}/>
                    Send
                    </a>

                </SharedCreation>
            </Content>
        </Container>
    }
    </>
    );
};

const SharedContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    vertical-align: baseline;
    background: transparent;
    padding: 8px 12px;
`;

const CancelButtonArea = styled.div`
    a {
        color: red;
        float: right;
        font-weight: 500;
        cursor: pointer;
    }
`;

const VideoInput = styled.input`
    width: 100%;
    height: 35px;
    margin-bottom: 20px;
`;

const SharedCreation = styled.div`
     display: flex;
     justify-content: space-between;
     padding: 12px 24px 12px 16px;
`;

const AssetButton = styled.button`
    display: flex;
    align-items: center;
    height: 40px;
    min-width: auto;
    color: rgba(0. 0, 0, 0.5);
`;

const AttachAssets = styled.div`
    align-items: center;
    display: flex;
    padding-right: 8px;
    a {
        cursor: pointer;
    }
    ${AssetButton} {
        width: 40px;
    }
`;

const SharedComment = styled.div`
    padding-left: 8px;
    margin-right: auto;
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    a {
        margin-top: 8px;
        cursor: pointer;
    }
    ${AssetButton} {
        svg {
            margin-right: 5px;
        }
    }
`;

const Editor = styled.div`
     padding: 12px 24px;
     textarea {
         width: 100%;
         min-height: 100px;
         resize: none;
     }
     input {
         width: 100%;
         height: 35px;
         font-size: 16px;
         margin-bottom: 20px;
     }
`;

const UploadImage = styled.div`
     text-align: center;
     margin-right: 8%;
     p {
         color: #2176FD;
         font-weight: 500;
         a {
            font-weight: 700;
            font-size: 13px;
            cursor: pointer;
         }
     }
     img {
         width: 100%;
     }
`;

const Container = styled.div`
    position: fixed;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     z-index: 9999;
     color: black;
     background-color: rgba(0, 0, 0, 0.8);
     animation: fadeIn 0.3s;
`;

const Content = styled.div`
    width: 100%;
    max-width: 552px;
    background-color: white;
    max-height: 90%;
    overflow: initial;
    border-radius: 12px;
    position: relative;
    display: flex;
    flex-direction: column;
    top: 32px;
    margin: 0 auto;
`;

const Header = styled.div`
    display: block;
    padding: 16px 20px;
    font-size: 14px;
    line-height: 1.5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
        font-weight: 700;
        color: black;
    }
    button {
        height: 40px;
        width: 40px;
        min-width: auto;
        color: rgba(0, 0, 0, 0.15);
        svg, img {
            pointer-events: none;
        }
    }
`;

const ProfilePic = styled.figure`
    img {
        width: 30px;
        height: 30px;
        object-fit: cover;
   }
`;

PostModal.propTypes = {
    user: PropTypes.object.isRequired,
    showModal: PropTypes.bool.isRequired,
    handleClick: PropTypes.func.isRequired,
    initialAssetArea: PropTypes.string
};

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, { createPost })(PostModal);