import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createSubscription } from "../redux/actions/userActions";

const Pricing = (props) => {
    const [schedule, setSchedule] = useState("monthly");

    const handleChange = (e) => {
        setSchedule(e.target.value);
    };

    const handleClick = (e) => {
        e.preventDefault();
        if (props.user.authenticated) {
            switch (e.target.name) {
                case "trial-button":
                    const trialSubDetails = {
                        pricing: "trial",
                        annual: schedule === "monthly" ? false : true,
                        trial: true,
                    };
                    props.createSubscription(trialSubDetails, props.navigate);
                    break;
                case "standard-button":
                    const standardSubDetails = {
                        pricing: "standard",
                        annual: schedule === "monthly" ? false : true,
                        trial: false,
                    };
                    props.createSubscription(
                        standardSubDetails,
                        props.navigate
                    );
                    break;
                case "professional-button":
                    alert("Picked professional");
                    const proSubDetails = {
                        pricing: "professional",
                        annual: schedule === "monthly" ? false : true,
                        trial: false,
                    };
                    props.createSubscription(proSubDetails, props.navigate);
                    break;
                default:
                    break;
            }
        } else {
            window.location.href = "/login";
        }
    };
    return (
        <>
            <Nav>
                <NavbarContainer>
                    <NavLogo to="/">
                        <i className="icon-trefoil pe-1" />
                        BLYNK
                    </NavLogo>
                    <NavMenu>
                        <NavItem>
                            <NavAction href="https://joinblynk.com">
                                About
                            </NavAction>
                        </NavItem>
                        <NavItem>
                            <NavAction href="https://joinblynk.com">
                                Vision
                            </NavAction>
                        </NavItem>
                        <NavItem>
                            <NavAction href="https://joinblynk.com">
                                Features
                            </NavAction>
                        </NavItem>
                        <NavItem>
                            <NavAction href="/sign-up">Sign Up</NavAction>
                        </NavItem>
                    </NavMenu>
                    <NavButton>
                        <NavButtonAction href="/login">Login</NavButtonAction>
                    </NavButton>
                </NavbarContainer>
            </Nav>
            <Container id="pricing" className="pt-4 pb-4">
                <PricingHeading className="mt-4">Pricing</PricingHeading>
                <span className="text-grey-500 text-center mt-3 pe-3 ps-3">
                    Utilize one of our paid Enterprise plans to start hiring via
                    the BLYNK Network.
                </span>
                <div className="text-grey-500" onChange={handleChange}>
                    <span className="me-4">
                        <input
                            type="radio"
                            name="schedule"
                            value="monthly"
                            checked={schedule === "monthly"}
                            className="me-1"
                        />
                        Monthly
                    </span>
                    <span>
                        <input
                            type="radio"
                            name="schedule"
                            value="annual"
                            checked={schedule === "annual"}
                            className="me-1"
                        />
                        Annual
                    </span>
                </div>
                <PricingWrapper className="mt-3">
                    <PricingCard>
                        <PricingSubtitle>Free Trial</PricingSubtitle>
                        <span>
                            <span className="text-warning font-lg fw-600">
                                $
                            </span>
                            <PricingNumber className="text-warning">
                                0
                            </PricingNumber>
                        </span>
                        <PricingBody>
                            <i className="feather-check-circle text-warning" />{" "}
                            10 contacts for 2 weeks
                            <br />
                            <i className="feather-check-circle text-warning" />{" "}
                            No commitment necessary
                            <br />
                        </PricingBody>
                        <ButtonWrapper>
                            <a
                                name="trial-button"
                                onClick={handleClick}
                                className={`p-2 lh-20 w100 text-center font-xssss fw-600 ls-1 rounded-xl ${
                                    props.user.authenticated
                                        ? "bg-black me-2 text-white cursor-pointer"
                                        : "bg-greylight text-grey-500"
                                }`}
                            >
                                Buy
                            </a>
                        </ButtonWrapper>
                    </PricingCard>
                    <PricingCard>
                        <PricingSubtitle>Standard</PricingSubtitle>
                        <span>
                            <span className="text-success font-lg fw-600">
                                $
                            </span>
                            <PricingNumber className="text-success">
                                {schedule === "monthly" ? "120" : "1,150"}
                            </PricingNumber>
                            <span className="text-success font-xss fw-600">
                                {schedule === "monthly" ? "/mo" : "/yr"}
                            </span>
                        </span>
                        <PricingBody>
                            <i className="feather-check-circle text-success" />{" "}
                            30 contacts per month
                            <br />
                            <i className="feather-check-circle text-success" />{" "}
                            Suitable for several hires per year
                            <br />
                            <i className="feather-check-circle text-success" />{" "}
                            $1,150 billed annually (save $290)
                        </PricingBody>
                        <ButtonWrapper>
                            <a
                                name="trial-button"
                                onClick={handleClick}
                                className={`p-2 lh-20 w100 text-center font-xssss fw-600 ls-1 rounded-xl ${
                                    props.user.authenticated
                                        ? "bg-black me-2 text-white cursor-pointer"
                                        : "bg-greylight text-grey-500"
                                }`}
                            >
                                Buy
                            </a>
                        </ButtonWrapper>
                    </PricingCard>
                    <PricingCard>
                        <PricingSubtitle>Professional</PricingSubtitle>
                        <span>
                            <span className="text-secondary font-lg fw-600">
                                $
                            </span>
                            <PricingNumber className="text-secondary">
                                {schedule === "monthly" ? "300" : "2,880"}
                            </PricingNumber>
                            <span className="text-secondary font-xss fw-600">
                                {schedule === "monthly" ? "/mo" : "/yr"}
                            </span>
                        </span>
                        <PricingBody>
                            <i className="feather-check-circle text-secondary" />{" "}
                            100 contacts per month
                            <br />
                            <i className="feather-check-circle text-secondary" />{" "}
                            Suitable for constant hiring
                            <br />
                            <i className="feather-check-circle text-secondary" />{" "}
                            $2,880 billed annually (save $720)
                        </PricingBody>
                        <ButtonWrapper>
                            <a
                                name="trial-button"
                                onClick={handleClick}
                                className={`p-2 lh-20 w100 text-center font-xssss fw-600 ls-1 rounded-xl ${
                                    props.user.authenticated
                                        ? "bg-black me-2 text-white cursor-pointer"
                                        : "bg-greylight text-grey-500"
                                }`}
                            >
                                Buy
                            </a>
                        </ButtonWrapper>
                    </PricingCard>
                </PricingWrapper>
                <span className="text-grey-500 font-xsss mt-3">
                    Need more contacts? Please{" "}
                    <a href="mailto:support@joinblynk.com">contact sales</a>.
                </span>
            </Container>
        </>
    );
};

const Container = styled.div`
    height: calc(100vh - 48px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #010606;

    @media screen and (max-width: 1000px) {
        height: calc(100vh + 196px);
    }

    @media screen and (max-width: 768px) {
        height: calc(100vh + 512px);
    }
`;

const PricingWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    grid-gap: 16px;
    padding: 0 50px;

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
`;

const PricingCard = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    width: 350px;
    height: 320px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

    @media screen and (max-width: 400px) {
        width: 90vw;
    }

    //   transition: all 0.2s ease-in-out;

    //   &:hover {
    //     transform: scale(1.02);
    //     transition: all 0.2s ease-in-out;
    //     cursor: pointer;
    //   }
`;

const PricingHeading = styled.h1`
    font-size: 2.1rem;
    font-weight: bold;
    color: #fff;

    @media screen and (max-width: 480px) {
        font-size: 2rem;
    }
`;

const PricingNumber = styled.span`
    font-size: 3.1rem;
    font-weight: bold;
    color: #000;

    @media screen and (max-width: 480px) {
        font-size: 3.1rem;
    }
`;

const PricingSubtitle = styled.h2`
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 10px;
`;

const PricingBody = styled.p`
    font-size: 1rem;
    text-align: left;
`;

const Nav = styled.nav`
    background: #010606;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }
`;

const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1100px;
`;

const NavLogo = styled.a`
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 3rem;
    display: flex;
    align-items: center;
    margin-left: 24px;
    font-weight: bold;
    text-decoration: none;

    @media screen and (max-width: 340px) {
        font-size: 2rem;
    }

    &:hover {
        color: #fbaf03;
    }

    &:visited {
        color: #fff;

        &:hover {
            color: #fbaf03;
        }
    }
`;

const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const NavItem = styled.li`
    height: 80px;
    display: inline;
`;

const NavButton = styled.nav`
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const NavButtonAction = styled.a`
    border-radius: 50px;
    background: #fbaf03;
    white-space: nowrap;
    padding 10px 22px;
    color: #010606;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #010606;

    }
`;

const NavAction = styled.a`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    &.active {
        border-bottom: 3px solid #fbaf03;
    }

    &:hover {
        color: #fbaf03;
    }
`;

const ButtonWrapper = styled.div`
    height: 90px;
    width: 280px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
`;

const WithNavigate = (props) => {
    const navigate = useNavigate();
    return <Pricing {...props} navigate={navigate} />;
};

Pricing.propTypes = {
    user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
});

const mapActionsToProps = { createSubscription };

export default connect(mapStateToProps, mapActionsToProps)(WithNavigate);
