import {
    SET_POSTS,
    LOADING_DATA,
    LIKE_POST,
    DISLIKE_POST,
    UNLIKE_POST,
    UNDISLIKE_POST,
    DELETE_POST,
    LOADING_UI,
    SET_ERRORS,
    CLEAR_ERRORS,
    CREATE_POST,
    SET_POST,
    STOP_LOADING_UI,
    SUBMIT_COMMENT,
    SET_JOBS,
    SET_PROFILE,
    SET_EVENTS,
    CREATE_EVENT,
    DELETE_EVENT,
    SET_RESERVATIONS,
    STOP_LOADING_DATA
} from "../types";
import axios from "axios";
import mixpanel from "../../util/mixpanel";
import { storage } from "../../util/firebase";

export const getPosts = () => (dispatch) => {
    dispatch({ type: LOADING_DATA });
    axios
        .get("/posts")
        .then((res) => {
            dispatch({
                type: SET_POSTS,
                payload: res.data,
            });
        })
        .catch(() => {
            dispatch({
                type: SET_POSTS,
                payload: {},
            });
        });
};

export const getPost = (postId) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
        .get(`post/${postId}`)
        .then((res) => {
            dispatch({
                type: SET_POST,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_UI });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const createPost = (newPost) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    if (newPost.mediaImage) {
        const upload = storage
            .ref(`images/${newPost.mediaImage.name}`)
            .put(newPost.mediaImage);
        upload.on(
            "state_changed",
            (snapshot) => {
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(`Upload Status: ${progress}%`);
                if (snapshot.state === "RUNNING") {
                    console.log(`Upload Status: ${progress}%`);
                }
            },
            (error) => console.log(error.code),
            async () => {
                newPost.mediaImage = await upload.snapshot.ref.getDownloadURL();
                axios
                    .post("/post", newPost)
                    .then((res) => {
                        dispatch({
                            type: CREATE_POST,
                            payload: res.data,
                        });
                        dispatch(clearErrors());
                        dispatch({ type: STOP_LOADING_UI });
                    })
                    .catch((err) => {
                        dispatch({
                            type: SET_ERRORS,
                            payload: err.response.data,
                        });
                        dispatch({ type: STOP_LOADING_UI });
                    });
            }
        );
    } else {
        axios
            .post("/post", newPost)
            .then((res) => {
                dispatch({
                    type: CREATE_POST,
                    payload: res.data,
                });
                dispatch(clearErrors());
                dispatch({ type: STOP_LOADING_UI });
            })
            .catch((err) => {
                dispatch({
                    type: SET_ERRORS,
                    payload: err.response.data,
                });
                dispatch({ type: STOP_LOADING_UI });
            });
    }
};

export const likePost = (postId) => (dispatch) => {
    axios
        .get(`/post/${postId}/like`)
        .then((res) => {
            dispatch({
                type: LIKE_POST,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const unlikePost = (postId) => (dispatch) => {
    axios
        .get(`/post/${postId}/unlike`)
        .then((res) => {
            dispatch({
                type: UNLIKE_POST,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const dislikePost = (postId) => (dispatch) => {
    axios
        .get(`/post/${postId}/dislike`)
        .then((res) => {
            dispatch({
                type: DISLIKE_POST,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const undislikePost = (postId) => (dispatch) => {
    axios
        .get(`/post/${postId}/undislike`)
        .then((res) => {
            dispatch({
                type: UNDISLIKE_POST,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const submitComment = (postId, commentData) => (dispatch) => {
    axios
        .post(`/post/${postId}/comment`, commentData)
        .then((res) => {
            dispatch({
                type: SUBMIT_COMMENT,
                payload: res.data,
            });
            dispatch(clearErrors());
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data,
            });
        });
};

export const deletePost = (postId) => (dispatch) => {
    axios
        .delete(`/post/${postId}`)
        .then(() => {
            dispatch({
                type: DELETE_POST,
                payload: postId,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getCompanyData = (companyAddress) => (dispatch) => {
    dispatch({ type: LOADING_DATA });
    axios
        .get(`/company/${companyAddress}`)
        .then((res) => {
            dispatch({
                type: SET_POSTS,
                payload: res.data.posts,
            });
        })
        .catch((err) => {
            dispatch({
                type: SET_POSTS,
                payload: null,
            });
        });
};

export const getJobs = (queryDetails) => (dispatch) => {
    dispatch({ type: LOADING_DATA });
    axios
        .post("/jobs", queryDetails)
        .then((res) => {
            dispatch({
                type: SET_JOBS,
                payload: res.data,
            });
            mixpanel.track("Job Search", {
                query: queryDetails.search,
                location: queryDetails.location,
            });
        })
        .catch(() => {
            dispatch({
                type: SET_JOBS,
                payload: {},
            });
        });
};

export const createRating = (ratingDetails) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
        .post("/rating", ratingDetails)
        .then(() => {
            mixpanel.track("Rating", {
                rating: ratingDetails.rating,
            });
            dispatch({ type: STOP_LOADING_UI });
        })
        .catch((err) => {
            console.log(err);
            dispatch({ type: STOP_LOADING_UI });
        });
};

export const createComplaint = (complaintDetails) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
        .post("/complaint", complaintDetails)
        .then(() => {
            mixpanel.track("Complaint", {
                complaint: complaintDetails.complaint,
            });
            dispatch({ type: STOP_LOADING_UI });
        })
        .catch((err) => {
            console.log(err);
            dispatch({ type: STOP_LOADING_UI });
        });
};

export const getCompanyProfileContent = (address) => (dispatch) => {
    axios
        .get(`/company/${address}`)
        .then((res) => {
            const payloadData = {
                ...res.data.user,
                education: res.data.education,
            };

            // Remove sensitive info from payload
            if (payloadData.hasOwnProperty("cus_id")) {
                delete payloadData.cus_id;
            }
            if (payloadData.hasOwnProperty("sub_id")) {
                delete payloadData.sub_id;
            }
            if (payloadData.hasOwnProperty("client_secret")) {
                delete payloadData.client_secret;
            }
            dispatch({
                type: SET_PROFILE,
                payload: payloadData,
            });
        })
        .catch((err) => console.log(err));
};

export const getEvents = () => (dispatch) => {
    dispatch({ type: LOADING_DATA });
    axios
        .get("/events")
        .then((res) => {
            dispatch({
                type: SET_EVENTS,
                payload: res.data,
            });
        })
        .catch(() => {
            dispatch({
                type: SET_EVENTS,
                payload: {},
            });
        });
};

export const createEvent = (newEvent, navigate) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    if (newEvent.headerLogoUrl) {
        const logoUpload = storage
            .ref(`images/${newEvent.headerLogoUrl.name}`)
            .put(newEvent.headerLogoUrl);

        logoUpload.on(
            "state_changed",
            (snapshot) => {
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(`Upload Status: ${progress}%`);
                if (snapshot.state === "RUNNING") {
                    console.log(`Upload Status: ${progress}%`);
                }
            },
            (error) => console.log(error.code),
            async () => {
                newEvent.headerLogoUrl =
                    await logoUpload.snapshot.ref.getDownloadURL();

                if (newEvent.bannerUrl) {
                    const bannerUpload = storage
                        .ref(`images/${newEvent.bannerUrl.name}`)
                        .put(newEvent.bannerUrl);

                    bannerUpload.on(
                        "state_changed",
                        (snapshot) => {
                            const progress =
                                (snapshot.bytesTransferred /
                                    snapshot.totalBytes) *
                                100;
                            console.log(`Upload Status: ${progress}%`);
                            if (snapshot.state === "RUNNING") {
                                console.log(`Upload Status: ${progress}%`);
                            }
                        },
                        (error) => console.log(error.code),
                        async () => {
                            newEvent.bannerUrl =
                                await bannerUpload.snapshot.ref.getDownloadURL();
                            axios
                                .post("/meeting-create", newEvent)
                                .then((res) => {
                                    dispatch({
                                        type: CREATE_EVENT,
                                        payload: res.data,
                                    });
                                    dispatch(clearErrors());
                                    dispatch({ type: STOP_LOADING_UI });
                                    navigate("/events");
                                })
                                .catch((err) => {
                                    dispatch({
                                        type: SET_ERRORS,
                                        payload: err.response.data,
                                    });
                                    dispatch({ type: STOP_LOADING_UI });
                                    navigate("/events");
                                });
                        }
                    );
                }
            }
        );
    } else {
        axios
            .post("/meeting-create", newEvent)
            .then((res) => {
                dispatch({
                    type: CREATE_EVENT,
                    payload: res.data,
                });
                dispatch(clearErrors());
                dispatch({ type: STOP_LOADING_UI });
                navigate("/events");
            })
            .catch((err) => {
                dispatch({
                    type: SET_ERRORS,
                    payload: err.response.data,
                });
                dispatch({ type: STOP_LOADING_UI });
                navigate("/events");
            });
    }
};

export const deleteEvent = (eventId) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
        .delete(`/event/${eventId}`)
        .then(() => {
            dispatch({
                type: DELETE_EVENT,
                payload: eventId,
            });
            dispatch({ type: STOP_LOADING_UI });
        })
        .catch((err) => {
            console.log(err);
            dispatch({ type: STOP_LOADING_UI });
        });
};

export const getEventReservations = (eventId) => (dispatch) => {
    axios
        .get(`/event/rsvp?id=${eventId}`)
        .then((res) => {
            dispatch({
                type: SET_RESERVATIONS,
                payload: res.data.reservations
            });
        })
        .catch(err => {
            console.log(err);
            dispatch({type: STOP_LOADING_DATA})
        });
};

export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};
