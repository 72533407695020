import styled from "styled-components";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// UI Components
import ProfileCard from "../profile/ProfileCard";
import ComplaintButton from "./ComplaintButton";

const Leftside = () => {
    return (
        <Container>
            <ProfileCard />
            <div className="card w-100 shadow-xss rounded-xxl mb-3">
                <div className="card-body d-block p-4">
                    <h4 className="fw-700 mb-3 font-xsss text-grey-900">
                        Help <i className="feather-help-circle" />
                    </h4>
                </div>
                <Link className="card-body d-flex pt-0" to="/privacy">
                    <i className="feather-lock text-grey-500 me-3 font-lg" />
                    <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                        Privacy{" "}
                    </h4>
                </Link>
                <ComplaintButton />
            </div>
        </Container>
    );
};

const Container = styled.div`
    grid-area: leftside;
`;

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps)(Leftside);
