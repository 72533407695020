import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDFV1QkhHuBf6Otizx2QFaoUeHR9mxplgQ",
    authDomain: "blynk-781cc.firebaseapp.com",
    databaseURL: "https://blynk-781cc-default-rtdb.firebaseio.com",
    projectId: "blynk-781cc",
    storageBucket: "blynk-781cc.appspot.com",
    messagingSenderId: "984730550307",
    appId: "1:984730550307:web:c9163c7efa7cdcaef37196",
    measurementId: "G-9TPVERHXM3"
  };

  const firebaseApp = firebase.initializeApp(firebaseConfig);
  const db = firebaseApp.firestore();
  const auth = firebaseApp.auth();
  const googleProvider = new firebase.auth.GoogleAuthProvider();
  const facebookProvider = new firebase.auth.FacebookAuthProvider();
  const storage = firebase.storage();

  export { auth, googleProvider, facebookProvider, storage };

  export default db;