import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

// UI Componets
import ProgressDialog from "../../util/ProgressDialog";
import ReactPlayer from "react-player";
import CreateBreakoutButton from "./CreateBreakoutButton";

// Redux
import { connect } from "react-redux";
import { createEvent } from "../../redux/actions/dataActions";

const CreateEventForm = (props) => {
    const [state, setState] = useState({
        waitingRoom: true,
        title: "",
        duration: 1,
        start: null,
        mediaVideo: "",
        bannerUrl: "",
        headerLogoUrl: "",
        description: "",
        darkBackground: false,
        rooms: [],
    });

    const handleChange = (event) => {
        if (event.target.name === "admission") {
            setState({
                ...state,
                waitingRoom: event.target.value === "waiting-room",
            });
        } else if (event.target.name === "start") {
            const dateString = new Date(
                Date.parse(event.target.value)
            ).toISOString();
            setState({
                ...state,
                start: dateString,
            });
        } else if (
            event.target.name === "headerLogoUrl" ||
            event.target.name === "bannerUrl"
        ) {
            const image = event.target.files[event.target.files.length - 1];
            if (image === "" || image === undefined) {
                alert(`Unsupported file type: ${typeof image}`);
                return;
            }
            setState({
                ...state,
                [event.target.name]: image,
            });
        } else if (event.target.name === "theme") {
            setState({
                ...state,
                darkBackground: event.target.value === "dark",
            });
        } else {
            event.preventDefault();
            setState({
                ...state,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleRemove = (event) => {
        if (event.target.name === "remove-banner") {
            setState({
                ...state,
                bannerUrl: "",
            });
        } else if (event.target.name === "remove-logo") {
            setState({
                ...state,
                headerLogoUrl: "",
            });
        } else {
            return;
        }
    };

    const removeRoom = (index) => {
        const roomsList = state.rooms;
        if (roomsList[index]) {
            delete roomsList[index];
        }
        setState({
            ...state,
            rooms: roomsList,
        });
    };

    const handleBreakout = (name, participants) => {
        const room = { name };
        participants = participants.split(",").map((p) => p.trim().toLowerCase());
        room.participants = participants;
        setState({
            ...state,
            rooms: [...state.rooms, room],
        });
    };

    const handleSubmit = () => {
        const eventDetails = {
            ...state,
            instant: false,
            companyName: props.user.credentials.handle,
            companyImageUrl: props.user.credentials.imageUrl,
        };
        if (eventDetails.hasOwnProperty("confirmPassword")) {
            delete eventDetails.confirmPassword;
        }
        if (
            eventDetails.waitingRoom &&
            eventDetails.hasOwnProperty("password")
        ) {
            eventDetails.password = "";
        }
        props.createEvent(eventDetails, props.navigate);
    };

    const body = document.body;
    body.classList.replace("theme-dark", "theme-light");

    return (
        <>
            {props.loading && <ProgressDialog />}
            <div className="w-100 border-0 p-0 mb-4">
                <div className="card shadow-xss h75 d-block p-4 mb-2 ms-4 me-2 rounded-xxl">
                    <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center">
                        Create Event
                    </h2>
                </div>
                <div className="card-body p-lg-5 ps-4 pe-4 pb-4 pt-0 mt-0 w-100 border-0">
                    <form action="#" onChange={handleChange}>
                        <div className="row">
                            <div className="col-lg-6 mb-3">
                                <div className="form-group">
                                    <label className="mont-font fw-600 font-xsss mb-2">
                                        Title
                                    </label>
                                    <input
                                        type="text"
                                        name="title"
                                        className="rounded-xxxl ps-3 fw-500 border-light-md form-control"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div className="form-group">
                                    <label className="mont-font fw-600 font-xsss mb-2">
                                        Duration (minutes)
                                    </label>
                                    <input
                                        type="number"
                                        name="duration"
                                        className="rounded-xxxl ps-3 fw-500 border-light-md form-control"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 mb-3">
                                <div className="form-group">
                                    <label className="mont-font fw-600 font-xsss mb-2">
                                        Date & Time
                                    </label>
                                    <input
                                        type="datetime-local"
                                        name="start"
                                        className="rounded-xxxl ps-3 fw-500 border-light-md form-control"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div className="form-group">
                                    <label className="mont-font fw-600 font-xsss mb-2">
                                        Promo Video URL (optional)
                                    </label>
                                    <input
                                        type="url"
                                        name="mediaVideo"
                                        className="rounded-xxxl ps-3 fw-500 border-light-md form-control"
                                    />
                                </div>
                            </div>
                        </div>
                        {state.mediaVideo && (
                            <>
                                {ReactPlayer.canPlay(state.mediaVideo) && (
                                    <div className="card-body p-4 mb-3 rounded-3 overflow-hidden">
                                        <ReactPlayer
                                            width={"100%"}
                                            url={state.mediaVideo}
                                        />
                                    </div>
                                )}
                                <p className="text-dark font-xsss">
                                    <b>Don't see your video? </b>
                                    Please note that we currently only support
                                    content hosted on YouTube, Facebook, Twitch,
                                    SoundCloud, Streamable, Vimeo, Wistia,
                                    Mixcloud, DailyMotion and Kaltura.
                                </p>
                            </>
                        )}
                        <div className="mont-font fw-600 font-xs mb-3 mt-4 text-center w-100">
                            <label className="me-4 pointer">
                                <input
                                    type="radio"
                                    name="admission"
                                    value="waiting-room"
                                    checked={state.waitingRoom}
                                    className="me-1"
                                />
                                Waiting Room
                            </label>
                            <label className="pointer">
                                <input
                                    type="radio"
                                    name="admission"
                                    value="passcode"
                                    checked={!state.waitingRoom}
                                    className="me-1"
                                />
                                Pass Code
                            </label>
                        </div>
                        <div className="row">
                            {!state.waitingRoom && (
                                <>
                                    <div className="col-lg-6 mb-3">
                                        <div className="form-group">
                                            <label className="mont-font fw-600 font-xsss mb-2">
                                                Pass Code
                                            </label>
                                            <input
                                                type="password"
                                                name="password"
                                                className="rounded-xxxl ps-3 fw-500 border-light-md form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <div className="form-group">
                                            <label className="mont-font fw-600 font-xsss mb-2">
                                                Confirm Pass Code
                                            </label>
                                            <input
                                                type="password"
                                                name="confirmPassword"
                                                className="rounded-xxxl ps-3 fw-500 border-light-md form-control"
                                            />
                                        </div>
                                    </div>
                                    {!state.waitingRoom && state.password && state.confirmPassword && state.password !== state.confirmPassword && <p className="text-danger">These pass codes don't match</p>}
                                </>
                            )}
                            <div className="col-lg-12 mb-3">
                                <div className="mt-3 border-0">
                                    <div className="card-body d-flex justify-content-between align-items-end p-0">
                                        <div className="form-group mb-0 w-100">
                                            <label className="mont-font fw-600 font-xsss mb-2">
                                                Thumbnail Image
                                                {state.bannerUrl && (
                                                    <a
                                                        className="text-danger pointer"
                                                        name="remove-banner"
                                                        onClick={handleRemove}
                                                    >
                                                        <i className="feather-x me-2 ms-4" />
                                                        Remove
                                                    </a>
                                                )}
                                            </label>
                                            {state.bannerUrl ? (
                                                <div className="card-image">
                                                    <img
                                                        src={URL.createObjectURL(
                                                            state.bannerUrl
                                                        )}
                                                        className="rounded-3"
                                                        style={{
                                                            height: "215px",
                                                            width: "325px",
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <>
                                                    <input
                                                        type="file"
                                                        name="bannerUrl"
                                                        id="bannerUrl"
                                                        className="input-file"
                                                    />
                                                    <label
                                                        for="bannerUrl"
                                                        className="rounded-xxxl text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed mb-4"
                                                    >
                                                        <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                                        <span className="js-fileName">
                                                            Drag and drop or
                                                            click to attach
                                                        </span>
                                                    </label>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {state.headerLogoUrl && (
                                    <div className="mont-font fw-600 font-xs mb-3 mt-4 text-center w-100">
                                        <label className="me-4 pointer">
                                            <input
                                                type="radio"
                                                name="theme"
                                                value="light"
                                                checked={!state.darkBackground}
                                                className="me-1"
                                            />
                                            Light Theme
                                        </label>
                                        <label className="pointer">
                                            <input
                                                type="radio"
                                                name="theme"
                                                value="dark"
                                                checked={state.darkBackground}
                                                className="me-1"
                                            />
                                            Dark Theme
                                        </label>
                                    </div>
                                )}
                                <div className="mt-3 border-0">
                                    <div className="card-body d-flex justify-content-between align-items-end p-0">
                                        <div className="form-group mb-0 w-100">
                                            <label className="mont-font fw-600 font-xsss mb-2">
                                                Logo / Branding Image
                                                {state.headerLogoUrl && (
                                                    <a
                                                        className="text-danger pointer"
                                                        name="remove-logo"
                                                        onClick={handleRemove}
                                                    >
                                                        <i className="feather-x me-2 ms-4" />
                                                        Remove
                                                    </a>
                                                )}
                                            </label>
                                            {state.headerLogoUrl ? (
                                                <div
                                                    className={`border-dark-md h50 rounded-xxxl ${
                                                        state.darkBackground
                                                            ? "bg-black"
                                                            : "bg-white"
                                                    }`}
                                                >
                                                    <img
                                                        src={URL.createObjectURL(
                                                            state.headerLogoUrl
                                                        )}
                                                        alt=""
                                                        className="p-3"
                                                        style={{
                                                            maxWidth: "70vw",
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <>
                                                    <input
                                                        type="file"
                                                        name="headerLogoUrl"
                                                        id="headerLogoUrl"
                                                        className="input-file"
                                                    />
                                                    <label
                                                        for="headerLogoUrl"
                                                        className="rounded-xxxl text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                                                    >
                                                        <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                                        <span className="js-fileName">
                                                            Drag and drop or
                                                            click to attach
                                                        </span>
                                                    </label>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 mb-3">
                                <label className="mont-font fw-600 font-xsss mb-2">
                                    Description
                                </label>
                                <textarea
                                    className="rounded-xxxl ps-3 fw-500 border-light-md form-control mb-0 p-3 h100 lh-16"
                                    rows="5"
                                    placeholder="Tell us about your event..."
                                    name="description"
                                ></textarea>
                            </div>
                            <div className="col-lg-12 mb-4">
                                <label className="mont-font fw-600 font-xsss mb-2">
                                    Breakout Rooms
                                </label>
                                <CreateBreakoutButton
                                    addFunction={handleBreakout}
                                />
                                {state.rooms &&
                                    state.rooms.map((room, index) => (
                                        <div className="fw-600 ms-4">
                                            <label className="me-4">
                                                {room.name}
                                            </label>
                                            <label>
                                                ({room.participants.length}{" "}
                                                participant
                                                {room.participants.length > 1 &&
                                                    "s"}
                                                )
                                            </label>
                                            <a
                                                className="text-danger pointer"
                                                onClick={() =>
                                                    removeRoom(index)
                                                }
                                            >
                                                <i className="feather-x me-2 ms-4" />
                                                <span className="d-none-xs">
                                                    Remove
                                                </span>
                                            </a>
                                        </div>
                                    ))}
                            </div>
                            <div className="col-lg-12">
                                <a
                                    className="float-right bg-black text-center text-white font-xsss fw-600 p-3 w175 rounded-xxxl d-inline-block pointer"
                                    onClick={handleSubmit}
                                >
                                    <i className="feather-plus font-xs me-2" />
                                    Create Event
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

CreateEventForm.propTypes = {
    user: PropTypes.object.isRequired,
    createEvent: PropTypes.func.isRequired,
    loading: PropTypes.func.isRequired,
};

const WithNavigate = (props) => {
    const navigate = useNavigate();
    return <CreateEventForm {...props} navigate={navigate} />;
};

const mapStateToProps = (state) => ({
    user: state.user,
    loading: state.UI.loading,
});

export default connect(mapStateToProps, { createEvent })(WithNavigate);
