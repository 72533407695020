import React, { Component } from "react";
import styled from "styled-components";

// UI Components
import Navbar from "../components/layout/Navbar";
import SearchList from "../components/search/SearchList";

class Search extends Component {
    render() {
        return (
            <Container className="main-content right-chat-active">
                <Navbar />
                <h2 className="fw-700 text-grey-900 text-center lh-2">
                    Find who you're looking for in the{" "}
                    <span className="text-current">BLYNK </span>
                    of an eye.
                </h2>
                <SearchList withGraphic={true} />
            </Container>
        );
    }
}

const Container = styled.div`
    padding-top: 98px;
    width: 100vw;
    min-height: 100vh;
    padding-left: 16px !important;
    padding-right: 16px !important;
    padding-bottom: 110px;
`;

export default Search;
