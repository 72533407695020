import React, { useState } from "react";
import styled from "styled-components";

const NavDrawer = (props) => {

    const [open, setOpen] = useState(false);

    const toggleMenu = () => {
        setOpen(!open);
    };

    return (
        open? (
            <>
                <Container onClick={toggleMenu}/>
                <NavListItem>
                    <a 
                        className="menu-hamburger me-4"
                        onClick={toggleMenu}
                    >
                        <i className="feather-menu font-xxl" />
                    </a>
                    {open && props.children}
                </NavListItem>
            </>
        ): (
            <NavListItem>
                <a 
                className="menu-hamburger me-4"
                onClick={toggleMenu}
                >
                    <i className="feather-menu font-xxl" />
                </a>
                {open && props.children}
            </NavListItem>
        )     
    );
};

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: black;
    background-color: rgba(0, 0, 0, 0.8);
    animation: fadeIn 0.3s;
    z-index: 100;
`;

const NavListItem = styled.li`
    z-index: 120;
`;

export default NavDrawer;
