import styled from "styled-components";
import PropTypes from "prop-types";
import { toAddress } from "../../util/companyAddress";
import { Link } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import { logoutUser } from "../../redux/actions/userActions";

const NavDrawerMenu = (props) => {
    const NavDrawerTile = (props) => {
        return (
            <MenuItem
                className="rounded-3 pointer"
                onClick={props.onClick}
            >
                <Leading className="me-3">{props.leading}</Leading>
                {props.children}
                <Trailing className="ms-3">{props.trailing}</Trailing>
            </MenuItem>
        );
    };

    const Dropdown = styled.div`
        position: absolute;
        top: 0;
        left: 0;
        width: calc(45px + ${props.user.authenticated? props.user.credentials.email.length: 20} * 0.65rem);
        padding-bottom: 1rem;
        overflow: hidden;
        border-radius: 0 15px 15px 0;
    `;

    let address;
    if (props.user.authenticated) {
        address = toAddress(props.user.credentials.handle, props.user.credentials.userId);
    }

    return (
        <Dropdown className="card border-0">
            <Header className="bg-gradient">
                { !props.user.authenticated &&
                    <span className="text-dark fw-800 font-lg"><i className="icon-trefoil"/> BLYNK</span>
                }
                { props.user.authenticated &&
                    <>                  
                        <span
                            className="float-right fw-700 text-dark font-xssss mt-1"
                        >
                            {props.user.credentials.handle}
                            <div
                                className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"
                            >
                                {props.user.credentials.email}
                            </div>
                        </span>
                        <figure className="avatar me-3">
                            <img 
                                src={props.user.credentials.imageUrl}
                                alt=""
                                style={{maxHeight: "45px", objectFit: "cover"}}
                                className="shadow-sm rounded-circle w45"
                            />
                        </figure>            
                    </>                                   
                }
            </Header>

            { props.user.authenticated &&
                <>
                    <Link to={`/companies/${address}`}>
                    <NavDrawerTile
                        leading={<i className="feather-user text-dark" />}
                    >
                        <span className="text-dark">My Company</span>
                    </NavDrawerTile>
                    </Link>
                </>
            }       

            <Link to="/search">
                <NavDrawerTile
                    leading={<i className="feather-search text-dark" />}
                >
                    <span className="text-dark">Search</span>
                </NavDrawerTile>
            </Link>

            { props.user.authenticated &&
                <Link to="/events">
                   <NavDrawerTile
                       leading={<i className="feather-calendar text-dark" />}
                   >
                       <span className="text-dark">Events</span>
                   </NavDrawerTile>
               </Link>
            }

            <hr className="p-0 m-0"/>

            { props.user.authenticated &&
                <NavDrawerTile
                   leading={<i className="feather-log-out text-dark" />}
                   onClick={() => props.logoutUser()}
               >
                   <span className="text-dark">Sign Out</span>
               </NavDrawerTile>
            }

            { !props.user.authenticated &&
                <>
                    <Link to="/sign-up">
                        <NavDrawerTile
                            leading={<i className="feather-user-plus text-dark" />}
                        >
                            <span className="text-dark">Sign Up</span>
                        </NavDrawerTile>
                    </Link>

                    <Link to="/login">
                        <NavDrawerTile
                            leading={<i className="feather-log-in text-dark" />}
                        >
                            <span className="text-dark">Login</span>
                        </NavDrawerTile>
                    </Link>
               </>
            }
        </Dropdown>
    );
};

const MenuItem = styled.div`
    height: 50px;
    display: flex;
    align-items: center;
    padding: 1rem;

    &:hover {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
    }
`;

const Leading = styled.span`
    width: 20px;
    height: 20px;
`;

const Header = styled.div`
    height: 75px;
    box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.3);
    padding 1rem;
`;

const Trailing = styled.span`
    width: 20px;
    height: 20px;
`;

NavDrawerMenu.propTypes = {
    user: PropTypes.object.isRequired
};

export default connect(null, { logoutUser })(NavDrawerMenu);