import styled from "styled-components";
import FeedbackForm from "./FeedbackForm";
import GoogleAd from "./GoogleAd";

const Rightside = () => {
    return (
        <Container>
            <FeedbackForm />
            <BannerCard className="card w-100 shadow-xss rounded-xxl">
                <AdLabel>
                    <p>Sponsored </p>
                </AdLabel>
                <img
                    src="https://hbculifestyle.com/wp-content/uploads/2021/09/Pepsi-Fan-Favorite-HBCU-Scholarship-Fund.jpg"
                    alt=""
                />
            </BannerCard>
            {/* <BannerCard className="card w-100 shadow-xss rounded-xxl">
                <AdLabel>
                    <p>Sponsored </p>
                </AdLabel>
                <GoogleAd />
            </BannerCard> */}
        </Container>
    );
};

const Container = styled.div`
    grid-area: rightside;
    position: relative;
`;

const FollowCard = styled.div`
    text-align: left;
    overflow: hidden;
    margin-bottom: 16px;
    background-color: #fff;
    border-radius: 12px;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    padding: 12px;
`;

const AdLabel = styled.div`
    display: inline-flex;
    align-items: right;
    justify-content: space-between;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 8px;
`;

const BannerCard = styled(FollowCard)`
    position: absolute;
    width: parent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    img {
        width: 100%;
        height: 100%;
    }
`;

export default Rightside;
