import styled from "styled-components";
import { useState } from "react";
import PropTypes from "prop-types";

const ErrorDialog = (props) => {
    const [open, setOpen] = useState(true);   

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {open && (
                <Container>
                    <Content className="card w-100 shadow-xss rounded-xxl">
                        <Header className="shadow-xss">
                            <h2 style={{ whiteSpace: "nowrap" }}>Error</h2>
                            <CancelButtonArea className="card-body p-0">
                                <a
                                    className="font-xssss fw-600 text-warning-500"
                                    onClick={handleClose}
                                >
                                    <i
                                        className="btn-round-sm font-xs text-danger feather-x me-2 bg-danger-light"
                                        onClick={handleClose}
                                    />
                                    <br />
                                </a>
                            </CancelButtonArea>
                        </Header>
                        <hr
                            style={{
                                width: "100%",
                                margin: 0,
                                padding: 0,
                                backgroundColor: "#adb5bd",
                            }}
                        />
                        <ErrorText className="ps-4">
                            {props.message}
                        </ErrorText>
                    </Content>
                </Container>
            )}
        </>
    );
};

const ErrorText = styled.div`
    min-height: 125px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    vertical-align: baseline;
    background: transparent;
    padding: 8px 12px;
`;

const CancelButtonArea = styled.div`
    a {
        color: red;
        float: right;
        font-weight: 500;
        cursor: pointer;
    }
`;

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    color: black;
    background-color: rgba(0, 0, 0, 0.8);
    animation: fadeIn 0.3s;
`;

const Content = styled.div`
    width: 100%;
    max-width: 552px;
    background-color: white;
    max-height: 90%;
    overflow: initial;
    border-radius: 12px;
    position: relative;
    display: flex;
    flex-direction: column;
    top: 32px;
    margin: 0 auto;
`;

const Header = styled.div`
    display: block;
    padding: 16px 20px;
    font-size: 14px;
    line-height: 1.5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
        font-weight: 700;
        color: black;
    }
    button {
        height: 40px;
        width: 40px;
        min-width: auto;
        color: rgba(0, 0, 0, 0.15);
        svg,
        img {
            pointer-events: none;
        }
    }
`;

ErrorDialog.propTypes = {
    message: PropTypes.string.isRequired
};

export default ErrorDialog;
