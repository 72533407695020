import "./App.css";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import { Component } from "react";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { auth } from "./util/firebase";
import mixpanel from "./util/mixpanel";

// Redux
import { Provider } from "react-redux";
import store from "./redux/store";
import { SET_AUTHENTICATED } from "./redux/types";
import { getCompanyData } from "./redux/actions/userActions";

// Pages
import Feed from "./pages/Feed";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Company from "./pages/Company";
import Jobs from "./pages/Jobs";
import Privacy from "./pages/Privacy";
import NotFound from "./pages/NotFound";
import Search from "./pages/Search";
import Pricing from "./pages/Pricing";
import Payment from "./pages/Payment";
import Event from "./pages/Event";
import Events from "./pages/Events";
import CreateEvent from "./pages/CreateEvent";

// Components
import AuthRoute from "./util/AuthRoute";
import ScrollToTop from "../src/components/layout/ScrollToTop";

let token = localStorage.FBIdToken;

// Prevent proxy from including url path parameters in axios requests
axios.defaults.baseURL = "https://us-central1-blynk-781cc.cloudfunctions.net/api";

if (token) {
    const decodedToken = jwtDecode(token);

    // Check whether the current user's Firebase ID token is expired
    if (decodedToken.exp * 1000 < Date.now()) {
        auth.onIdTokenChanged((user) => {
            if (user) {
                user.getIdToken(true)
                    .then((idToken) => {
                        token = idToken.trim();
                        localStorage.setItem("FBIdToken", `Bearer ${token}`);
                        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
                        store.dispatch(getCompanyData());
                        store.dispatch({type: SET_AUTHENTICATED});
                        mixpanel.track("Login");
                    });
            }
        }); 
    } else {
        axios.defaults.headers.common["Authorization"] = token;
        store.dispatch(getCompanyData());
        store.dispatch({ type: SET_AUTHENTICATED });
    }
}

class App extends Component {
    render() {
        return (
            <>
                <Provider store={store}>
                    <Router>
                        <ScrollToTop />
                        <div className="container" style={{ bottom: "0" }}>
                            <Routes>
                                <Route path="/" element={token ? <Navigate to="/search" />: <Login />} />
                                <Route path="/feed" element={<Feed />} />
                                <Route path="/jobs" element={<Jobs />} />
                                <Route path="/privacy" element={<Privacy />} />
                                <AuthRoute path="/login" element={<Login />} />
                                <AuthRoute path="/sign-up" element={<SignUp />} />
                                <Route path="/companies/:handle" element={<Company />} />
                                <Route path="*" element={<NotFound />} />
                                <Route path="/search" element={<Search />} />
                                <Route path="/pricing" element={<Pricing />} />
                                {/* <Route path="/payment" element={<Payment />} /> */}
                                <Route path="/events" element={<Events />} />
                                <Route path="/events/:id" element={<Event />} />
                                <Route path="/event/create" element={token? <CreateEvent />: <Navigate to="/login" />} />
                            </Routes>
                        </div>
                    </Router>
                </Provider>
            </>
        );
    }
}

export default App;