import { Component, Fragment } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

class CreateBreakoutButton extends Component {
    constructor() {
        super();
        this.state = {
            showModal: false,
            name: "",
            participants: "",
        };
    }

    handleOpen = () => {
        this.setState({ showModal: true });
    };

    handleClose = () => {
        this.setState({ showModal: false });
    };

    handleChange = (event) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });
    };

    handleClick = () => {
        if (this.state.name && this.state.participants) {
            this.props.addFunction(this.state.name, this.state.participants);
            this.handleClose();
        }
    };

    render() {
        return (
            <Fragment>
                <div className="w-100 mt-4 mb-4">
                    <a
                        onClick={this.handleOpen}
                        className="pointer text-facebook rounded-xxxl font-xss fw-600"
                    >
                        <i className="feather-plus me-2" />
                        Add Room
                    </a>
                </div>
                {this.state.showModal && (
                    <Container>
                        <Content className="card w-100 shadow-xss rounded-xxl">
                            <Header className="shadow-xss">
                                <h2 style={{ whiteSpace: "nowrap" }}>
                                    Add Breakout Room
                                </h2>
                                <CancelButtonArea className="card-body p-0">
                                    <a
                                        className="font-xssss fw-600 text-warning-500"
                                        onClick={this.handleClose}
                                    >
                                        <i
                                            className="btn-round-sm font-xs text-danger feather-x me-2 bg-danger-light"
                                            onClick={this.handleClose}
                                        />
                                        <br />
                                    </a>
                                </CancelButtonArea>
                            </Header>
                            <hr
                                style={{
                                    width: "100%",
                                    margin: 0,
                                    padding: 0,
                                    backgroundColor: "#adb5bd",
                                }}
                            />
                            <div style={{ overflowY: "scroll" }}>
                                <div className="col-lg-12 mb-3 ps-4 pe-4">
                                    <label className="mont-font fw-600 font-xsss mt-4">
                                        Room Name
                                    </label>
                                    <input
                                        type="text"
                                        name="name"
                                        className="rounded-xxxl ps-3 fw-500 border-light-md form-control mb-4"
                                        onChange={this.handleChange}
                                    />
                                    <label className="mont-font fw-600 font-xsss mb-2">
                                        Participants
                                    </label>
                                    <textarea
                                        className="rounded-xxxl ps-3 fw-500 border-light-md form-control mb-0 p-3 h100 lh-16"
                                        rows="5"
                                        placeholder="Please add a comma-separated list of emails . . ."
                                        name="participants"
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <BottomButtonArea>
                                <a
                                    className={`p-2 lh-20 w100 me-2 text-center font-xssss fw-600 ls-1 rounded-xl ${this.state.name && this.state.participants? "text-white bg-blue-gradient": "text-grey-500 bg-greylight"}`}
                                    onClick={this.handleClick}
                                >
                                    <i className="font-xs feather-plus me-2 bg-transparent" />
                                    Add
                                </a>
                            </BottomButtonArea>
                        </Content>
                    </Container>
                )}
            </Fragment>
        );
    }
}

const BottomButtonArea = styled.div`
    padding: 12px 24px 12px 16px;
    a {
        float: right;
        cursor: pointer;
    }
`;

const CancelButtonArea = styled.div`
    a {
        color: red;
        float: right;
        font-weight: 500;
        cursor: pointer;
    }
`;

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    color: black;
    background-color: rgba(0, 0, 0, 0.8);
    animation: fadeIn 0.3s;
`;

const Header = styled.div`
    display: block;
    padding: 16px 20px;
    font-size: 14px;
    line-height: 1.5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
        font-weight: 700;
        color: black;
    }
    button {
        height: 40px;
        width: 40px;
        min-width: auto;
        color: rgba(0, 0, 0, 0.15);
        svg,
        img {
            pointer-events: none;
        }
    }
`;

const Content = styled.div`
    width: 100%;
    max-width: 552px;
    background-color: white;
    max-height: 90%;
    overflow: initial;
    border-radius: 12px;
    position: relative;
    display: flex;
    flex-direction: column;
    top: 32px;
    margin: 0 auto;
`;

CreateBreakoutButton.propTypes = {
    addFunction: PropTypes.func.isRequired,
};

export default CreateBreakoutButton;
