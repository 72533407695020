import styled from "styled-components";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { submitComment } from "../../redux/actions/dataActions";
import { getPost, clearErrors } from "../../redux/actions/dataActions";
import { toAddress } from "../../util/companyAddress";
import PropTypes from "prop-types";
import progressSpinner from "../../images/spin-loader.svg";
import DeletePostButton from "./DeletePostButton";
import { Link } from "react-router-dom";
import { renderWithLinks } from "../../util/TextFormat";

const CommentModal = (props) => {
    const {
        user: {
            credentials: { userHandle, userImage },
        },
    } = props;
    const initalOldPath = window.location.pathname;
    const initialAddress = toAddress(props.post.userHandle, props.post.userId);
    const [address, setAddress] = useState(initialAddress);
    const initialNewPath = `/users/${address}/post/${props.postId}`;
    const [commentBody, setCommentBody] = useState("");
    const [oldPath, setOldPath] = useState(initalOldPath);
    const [newPath, setNewPath] = useState(initialNewPath);

    useEffect(() => {
        if (props.showModal) {
            window.history.pushState(null, null, newPath);
        }
    });

    useEffect(() => {
        setNewPath(`/users/${address}/post/${props.postId}`);
    }, [address]);

    useEffect(() => {
        if (oldPath === newPath) {
            setOldPath(`/users/${address}`);
        }
    }, [newPath]);

    useEffect(() => {
        if (props.showModal) {
            setAddress(toAddress(props.post.userHandle, props.post.userId));
            window.history.pushState(null, null, newPath);
            props.getPost(props.postId);
        }
    }, [props.showModal, props.postId, props.post.userHandle]);

    const handleClose = (event) => {
        window.history.pushState(null, null, oldPath);
        props.clearErrors();
        reset(event);
    };

    const sendComment = (e) => {
        e.preventDefault();
        if (e.target !== e.currentTarget) {
            return;
        }
        const payload = {
            body: commentBody,
            userHandle,
            userImage,
        };
        props.submitComment(props.postId, payload);
        setCommentBody("");
    };

    const reset = (e) => {
        setCommentBody("");
        props.handleClick(e);
    };

    return (
        <>
            {props.showModal && (
                <Container>
                    <Content className="card w-100 shadow-xss rounded-xxl">
                        <Header className="shadow-xss">
                            <h2 style={{ whiteSpace: "nowrap" }}>Comments</h2>
                            <CancelButtonArea className="card-body p-0">
                                <a
                                    className="font-xssss fw-600 text-warning-500"
                                    onClick={handleClose}
                                >
                                    <i
                                        className="btn-round-sm font-xs text-danger feather-x me-2 bg-danger-light"
                                        onClick={handleClose}
                                    />
                                    <br />
                                </a>
                            </CancelButtonArea>
                        </Header>
                        <hr
                            style={{
                                width: "100%",
                                margin: 0,
                                padding: 0,
                                backgroundColor: "#adb5bd",
                            }}
                        />

                        <SharedContent>
                            <div className="card w-100 shadow-xss rounded-xxl p-4 mb-3">
                                {props.user.authenticated &&
                                    props.user.credentials.userId ===
                                        props.post.userId && (
                                        <>
                                            <DeletePostButton
                                                postId={props.postId}
                                            />
                                            <div className="h45 w150 mb-4" />
                                        </>
                                    )}
                                <Link
                                    className={`pointer ${
                                        props.user.authenticated &&
                                        props.user.credentials.userId ===
                                            props.post.userId
                                            ? "position-absolute"
                                            : ""
                                    }`}
                                    to={`/users/${address}`}
                                >
                                    <div className="card-body p-0 d-flex">
                                        <figure className="avatar me-3">
                                            <img
                                                src={props.post.userImage}
                                                alt=""
                                                style={{
                                                    maxHeight: "45px",
                                                    objectFit: "cover",
                                                }}
                                                className="shadow-sm rounded-circle w45"
                                            />
                                        </figure>
                                        <PostHeaderText className="fw-700 text-grey-900 font-xssss mt-1">
                                            {" "}
                                            {props.post.userHandle}{" "}
                                            <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                                                {" "}
                                                {props.post.createdAt &&
                                                    new Date(
                                                        props.post.createdAt
                                                            ._seconds * 1000
                                                    ).toLocaleString()}
                                            </span>
                                        </PostHeaderText>
                                    </div>
                                </Link>
                                <div className="card-body p-0 me-lg-5">
                                    <PostText className="fw-500 text-black-500 lh-26 font-xssss w-100 mb-2">
                                        {props.post.body && renderWithLinks(props.post.body)}
                                    </PostText>
                                </div>
                            </div>

                            {props.user.authenticated && (
                                <Editor>
                                    <div className="card-body p-0 mt-3 position-relative">
                                        {props.user.credentials && (
                                            <ProfilePic className="avatar position-absolute ms-2 mt-1 top-5">
                                                <img
                                                    src={
                                                        props.user.credentials
                                                            .imageUrl ??
                                                        "images/user.svg"
                                                    }
                                                    alt="profile"
                                                    className="shadow-sm rounded-circle w30"
                                                />
                                            </ProfilePic>
                                        )}
                                        <textarea
                                            name="message"
                                            className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg"
                                            cols="30"
                                            rows="10"
                                            placeholder="What's on your mind?"
                                            value={commentBody}
                                            onChange={(e) =>
                                                setCommentBody(e.target.value)
                                            }
                                            autoFocus={true}
                                        />
                                        <a
                                            className={`p-2 lh-20 w100 me-2 text-center font-xssss fw-600 ls-1 rounded-xl ${
                                                commentBody
                                                    ? "bg-gold-gradient text-white"
                                                    : "bg-greylight text-grey-500"
                                            }`}
                                            onClick={(event) =>
                                                commentBody
                                                    ? sendComment(event)
                                                    : {}
                                            }
                                            style={{ float: "right" }}
                                        >
                                            <i
                                                className={`font-xs feather-send me-2 bg-transparent ${
                                                    commentBody
                                                        ? "text-white"
                                                        : "text-grey-500"
                                                }`}
                                            />
                                            Send
                                        </a>
                                    </div>
                                </Editor>
                            )}

                            {props.post.comments &&
                                props.post.comments.map((comment, index) => {
                                    return props.UI.loading ? (
                                        <div
                                            style={{ alignItems: "center" }}
                                            key={index}
                                        >
                                            <img
                                                src={progressSpinner}
                                                alt=""
                                                key={index}
                                                style={{ width: "175px" }}
                                            />
                                        </div>
                                    ) : (
                                        <div key={index}>
                                            <hr
                                                style={{
                                                    backgroundColor: "#adb5bd",
                                                    cursor: "default",
                                                    width: "100%",
                                                }}
                                            />
                                            <div className="w-100 rounded-xxl p-4 mb-3">
                                                <div className="card-body p-0 d-flex">
                                                    <figure className="avatar me-3">
                                                        <img
                                                            src={
                                                                comment.userImage
                                                            }
                                                            alt=""
                                                            style={{
                                                                maxHeight:
                                                                    "30px",
                                                                objectFit:
                                                                    "cover",
                                                            }}
                                                            className="shadow-sm rounded-circle w30"
                                                        />
                                                    </figure>
                                                    <PostHeaderText className="fw-700 text-grey-900 font-xssss mt-1">
                                                        {comment.userHandle}
                                                        <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                                                            {comment.createdAt &&
                                                                new Date(
                                                                    comment
                                                                        .createdAt
                                                                        ._seconds *
                                                                        1000
                                                                ).toLocaleString()}
                                                        </span>
                                                    </PostHeaderText>
                                                </div>
                                                <div className="card-body p-0 me-lg-5">
                                                    <PostText className="fw-500 text-black-500 lh-26 font-xssss w-100 mb-2">
                                                        {renderWithLinks(
                                                            comment.body
                                                        )}
                                                    </PostText>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </SharedContent>
                    </Content>
                </Container>
            )}
        </>
    );
};

const SharedContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    vertical-align: baseline;
    background: transparent;
    padding: 8px 12px;
`;

const CancelButtonArea = styled.div`
    a {
        color: red;
        float: right;
        font-weight: 500;
        cursor: pointer;
    }
`;

const Editor = styled.div`
    padding: 12px 24px;
    textarea {
        width: 100%;
        min-height: 100px;
        resize: none;
    }
    input {
        width: 100%;
        height: 35px;
        font-size: 16px;
        margin-bottom: 20px;
    }
`;

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    color: black;
    background-color: rgba(0, 0, 0, 0.8);
    animation: fadeIn 0.3s;
`;

const Content = styled.div`
    width: 100%;
    max-width: 552px;
    background-color: white;
    max-height: 90%;
    overflow: initial;
    border-radius: 12px;
    position: relative;
    display: flex;
    flex-direction: column;
    top: 32px;
    margin: 0 auto;
`;

const Header = styled.div`
    display: block;
    padding: 16px 20px;
    font-size: 14px;
    line-height: 1.5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
        font-weight: 700;
        color: black;
    }
    button {
        height: 40px;
        width: 40px;
        min-width: auto;
        color: rgba(0, 0, 0, 0.15);
        svg,
        img {
            pointer-events: none;
        }
    }
`;

const ProfilePic = styled.figure`
    img {
        width: 30px;
        height: 30px;
        object-fit: cover;
    }
`;

const PostText = styled.p`
    text-align: left;
    white-space: pre-wrap;
`;

const PostHeaderText = styled.h4`
    text-align: left;
`;

CommentModal.propTypes = {
    user: PropTypes.object.isRequired,
    postId: PropTypes.string.isRequired,

    getPost: PropTypes.func.isRequired,
    userHandle: PropTypes.string.isRequired,
    post: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired,
    clearErrors: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
    post: state.data.post,
    UI: state.UI,
});

const mapActionsToProps = {
    submitComment,
    getPost,
    clearErrors,
};

export default connect(mapStateToProps, mapActionsToProps)(CommentModal);
