import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { toAddress } from "../../util/companyAddress";
import { renderWithLinks } from "../../util/TextFormat";

// Redux
import { connect } from "react-redux";
import { logoutUser, uploadImage } from "../../redux/actions/userActions";

// UI Components
import LoadingBar from "../../util/LoadingBar";
import EditProfileButton from "../profile/EditProfileButton";
import EditProfilePicButton from "../profile/EditProfilePicButton";

export class ProfileCard extends Component {
    handleImageChange = (event) => {
        const image = event.target.files[0];
        const formData = new FormData();
        formData.append("image", image, image.name);
        this.props.uploadImage(formData);
    };

    handleEditPicture = () => {
        const fileInput = document.getElementById("imageInput");
        fileInput.click();
    };

    handleLogout = () => {
        this.props.logoutUser();
    };

    render() {
        const {
            user: {
                credentials: {
                    handle,
                    userId,
                    createdAt,
                    imageUrl,
                    bio,
                    website,
                    location,
                    email,
                    postCount
                },
                 loading,
                 authenticated
                }
        } = this.props;

        const profileUrl = `/companies/${toAddress(handle, userId)}`;

        let profileMarkup = !loading? (authenticated? (
            <Fragment>
                  <div className="card w-100 shadow-xss rounded-xxl overflow-hidden mb-3 pb-3">
                  <div
                    className="card-body position-relative h150 bg-image-cover bg-image-center"
                    style={{backgroundImage: `url("https://i.pinimg.com/originals/49/61/5f/49615f86f34e3b5461a82e7a7d83121b.jpg")`}}
                  />
                <div className="card-body d-block pt-4 text-center">
                    <Link className="pointer" to={profileUrl}>
                        <figure className="avatar mt--6 position-relative w75 z-index-1 w100 z-index-1 ms-auto me-auto">
                            <img src={imageUrl} alt="" style={{maxHeight: "100px", objectFit: "cover"}} className="p-1 bg-white rounded-xl w-100" />
                        </figure>
                    </Link>
                    <ProfilePicButtonContainer>
                            <EditProfilePicButton/>
                    </ProfilePicButtonContainer>
                    <Link
                        className="font-xs ls-1 fw-700 text-dark pointer"
                        to={profileUrl}
                    >
                        {handle}
                        <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                            {email}
                        </span>
                       <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                           joined {
                                dayjs(new Date(createdAt._seconds * 1000).toLocaleString()).fromNow()
                           }
                       </span>
                    </Link>
                </div>
                <div className="card-body d-flex align-items-center ps-4 pe-4 pt-0">
                    {/* <h4 className="font-xsssss text-center text-grey-500 fw-600 ms-2 me-2"><b className="text-grey-900 mb-1 font-xss fw-700 d-inline-block ls-3 text-dark">{postCount? postCount: 0}<br/> </b> Posts</h4> */}
                    {/* <h4 className="font-xsssss text-center text-grey-500 fw-600 ms-2 me-2"><b className="text-grey-900 mb-1 font-xss fw-700 d-inline-block ls-3 text-dark">0<br/> </b> Followers</h4> */}
                    {/* <h4 className="font-xsssss text-center text-grey-500 fw-600 ms-2 me-2"><b className="text-grey-900 mb-1 font-xss fw-700 d-inline-block ls-3 text-dark">0<br/> </b> Following</h4> */}
                </div>
                <div className="card-body d-flex align-items-center justify-content-center ps-4 pe-4 pt-0">
                    <Link 
                        className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3"
                        to={profileUrl}
                    >
                        Profile
                    </Link>
                    {/* <a className="bg-greylight btn-round-lg ms-2 rounded-3 text-grey-700"><i className="feather-mail font-md"></i></a> */}
                    <Link
                        className="bg-greylight theme-white-bg btn-round-lg ms-2 rounded-3 text-grey-700"
                        to={profileUrl}
                    >
                        <i className="ti-more font-md"/>
                    </Link>
                </div>
            </div>

            <div className="card w-100 shadow-xss rounded-xxl mb-3">
                <div className="card-body d-block p-4">
                    <EditProfileButton/>
                    <h4 className="fw-700 mb-3 font-xsss text-grey-900">About</h4>
                    <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0" style={{whiteSpace: "pre-wrap"}}>{bio? renderWithLinks(bio): ""}</p>
                </div>
                <div className="card-body d-flex pt-0">
                    <i className="feather-eye text-grey-500 me-3 font-lg"/>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-0">Visble <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">Anyone can find you</span></h4>
                </div>
                { location &&
                    <div className="card-body d-flex pt-0">
                        <i className="feather-map-pin text-grey-500 me-3 font-lg"/>
                        <h4 className="fw-700 text-grey-900 font-xssss mt-1">{location}</h4>
                    </div>
                }                          
            </div>
        </Fragment>
        ): (
            <JoinCard className="card w-100 shadow-xss rounded-xxl">
            <div className="nav-caption fw-700 font-xssss text-grey-500"><JoinTitle>Join the Conversation</JoinTitle></div>
      
              <div className="wrap">                        
                          <div className="card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-4">
                              <Link to="/login" className="p-2 lh-20 w100 bg-gold-gradient me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Login</Link>
                              <Link to="/sign-up" className="p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl">Sign Up</Link>
                          </div>
                      </div>      
            </JoinCard>       
        )): (<LoadingBar/>);

        return profileMarkup;
    }
}

const JoinCard = styled.div`
  text-align: left;
  overflow: hidden;
  margin-bottom: 16px;
  background-color: #fff;
  border-radius: 12px;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 12px;
`;

const JoinTitle = styled.h4`
    font-size: 14px;
    font-weight: 700;
`;

const ProfilePicButtonContainer = styled.span`
    position: absolute;
    top: 45%;
    left: calc(50% + 30px);
    z-index: 64;
`;

ProfileCard.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    uploadImage: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    user: state.user
});

const mapActionsToProps = { logoutUser, uploadImage };

export default connect(mapStateToProps, mapActionsToProps)(ProfileCard);