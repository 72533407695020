import axios from "axios";
import mixpanel from "../../util/mixpanel";
import {
    auth,
    googleProvider,
    facebookProvider
} from "../../util/firebase";

import {
    SET_USER,
    SET_ERRORS,
    CLEAR_ERRORS,
    LOADING_UI,
    SET_UNAUTHENTICATED,
    LOADING_USER,
    MARK_NOTIFICATIONS_READ,    
    UPDATE_PROFILE,
    STOP_LOADING_UI,
} from "../types";

export const logoutUser = () => (dispatch) => {
    localStorage.removeItem("FBIdToken");
    delete axios.defaults.headers.common["Authorization"];
    dispatch({type: SET_UNAUTHENTICATED});
    window.location.href = "/login";
};

export const loginUserWithEmailAndPassword = (userData, navigate) => (dispatch) => {
    dispatch({type: LOADING_UI});
    auth.signInWithEmailAndPassword(userData.email, userData.password)
        .then(() => {
            return auth.currentUser.getIdToken(true);
        })
        .then((token) => {
            setAuthorizationHeader(token.trim());
            dispatch(getCompanyData());
            dispatch({type: CLEAR_ERRORS});
            mixpanel.track("Login", {
                provider: "Email & Password"
            });
            navigate("/search");
        })
        .catch((error) => {
            dispatch({
                type: SET_ERRORS,
                payload: error.message
            });
        });
};

export const loginUserWithGoogle = (navigate) => (dispatch) => {
    auth.signInWithPopup(googleProvider)
        .then(() => {
            return auth.currentUser.getIdToken(true);
        })
        .then((token) => {
            setAuthorizationHeader(token.trim());
            dispatch(getCompanyData());
            dispatch({type: CLEAR_ERRORS});
            mixpanel.track("Login", {
                provider: "Google"
            });
            navigate("/search");
        })
        .catch((error) => {
            dispatch({
                type: SET_ERRORS,
                payload: error.message
            });
        });
};

export const loginUserWithFacebook = (navigate) => (dispatch) => {
    auth.signInWithPopup(facebookProvider)
        .then(() => {
            return auth.currentUser.getIdToken(true);
        })
        .then((token) => {
            setAuthorizationHeader(token.trim());
            dispatch(getCompanyData());
            dispatch({type: CLEAR_ERRORS});
            mixpanel.track("Login", {
                provider: "Facebook"
            });
            navigate("/search");
        })
        .catch((error) => {
            dispatch({
                type: SET_ERRORS,
                payload: error.message
            });
        });
};

export const getCompanyData = () => (dispatch) => {
    dispatch({type: LOADING_USER});
    axios.get("/company")
        .then(res => {
            dispatch({
                type: SET_USER,
                payload: res.data
            });
        })
        .catch(err => {
            console.log(err);
        });
};

export const onboardCompany = (newCompanyData, navigate) => (dispatch) => {
    dispatch({ type: LOADING_UI});
    axios
        .post("/onboard", newCompanyData)
        .then(res => {     
            setAuthorizationHeader(res.data.token);
            dispatch(getCompanyData());
            dispatch({type: CLEAR_ERRORS});
            navigate("/");
        })
        .catch(err => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            });         
        });
};

export const uploadImage = (formData) => (dispatch) => {
    dispatch({type: LOADING_USER});
    axios.post("/company/logo", formData)
        .then((res) => {
            console.log(res);
            dispatch(getCompanyData());
        })
        .catch(err => {
            console.log(err);
        });
};

export const editCompanyDetails = (CompanyDetails) => (dispatch) => {
    dispatch({type: LOADING_USER});
    axios.post("/company", CompanyDetails)
        .then(() => {
            dispatch({
                type: UPDATE_PROFILE,
                payload: CompanyDetails
            });
            dispatch(getCompanyData());
        })
        .catch(err => {
            console.log(err);
        });
};

export const markNotificationsRead = (notificationIds) => (dispatch)  => {
    axios.post("notifications", notificationIds)
        .then(() => {
            dispatch({type: MARK_NOTIFICATIONS_READ});
        })
        .catch(err => {
            console.log(err);
        });
};

export const createSubscription = (subscriptionDetails, navigate) => (dispatch) => {
    dispatch({type: LOADING_UI});

    axios.post("/subscription", subscriptionDetails)
    .then(() => {
        dispatch({type: STOP_LOADING_UI});
        navigate("/payment");
    })
    .catch(err => {
        console.log(err);
        dispatch({type: STOP_LOADING_UI});
    });
    
};

const setAuthorizationHeader = (token) => {
    const FBIdToken = `Bearer ${token}`;
    localStorage.setItem("FBIdToken", FBIdToken);
    axios.defaults.headers.common["Authorization"] = FBIdToken;
};